import React, { useState } from "react";
import { Card, Container, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Customerapproval = () => {
  const [oldDistributorWorking, setOldDistributorWorking] = useState(false);
  const [fullFinalSubmitted, setFullFinalSubmitted] = useState(false);

  return (
    <Container className="mt-4">
      <header className="mb-4">
        <h1>Customer Approval</h1>
      </header>

      {/* Distributorship Information */}
      <Card className="mb-4">
        <Card.Body>
          <h5>Distributorship Information</h5>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>Customer Code</Form.Label>
            <Form.Control type="text" readOnly />
          </Form.Group>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>Name of Distributorship</Form.Label>
            <Form.Control type="text" readOnly />
          </Form.Group>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>Name of Proprietor/Partner</Form.Label>
            <Form.Control type="text" readOnly />
          </Form.Group>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>Address of Distributor’s Shop</Form.Label>
            <Form.Control type="text" readOnly />
          </Form.Group>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>Customer Mobile Number</Form.Label>
            <Form.Control type="text" readOnly />
          </Form.Group>
        </Card.Body>
      </Card>

      {/* Sales Information */}
      <Card className="mb-4">
        <Card.Body>
          <h5>Sales Information</h5>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>Present Sale of Competition (INR)</Form.Label>
            <Form.Control type="text" readOnly />
          </Form.Group>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>Present Sale of Competition (Ctn/month)</Form.Label>
            <Form.Control type="text" readOnly />
          </Form.Group>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>No. of Sales Points / Retailers Currently Serviced</Form.Label>
            <Form.Control type="text" readOnly />
          </Form.Group>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>Expected Sale</Form.Label>
            <Form.Control type="text" readOnly />
          </Form.Group>
        </Card.Body>
      </Card>

      {/* Business Commencement */}
      <Card className="mb-4">
        <Card.Body>
          <h5>Business Commencement</h5>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>Date of Commencement of Business</Form.Label>
            <Form.Control type="date" readOnly />
          </Form.Group>
        </Card.Body>
      </Card>

      {/* Distributor Transition Information */}
      <Card className="mb-4">
        <Card.Body>
          <h5>Distributor Transition Information</h5>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>Old Distributor Still Working</Form.Label>
            <Form.Check 
              type="switch" 
              id="oldDistributorWorking" 
              label={oldDistributorWorking ? "Yes" : "No"}
              checked={oldDistributorWorking}
              readOnly
            />
          </Form.Group>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label>Full & Final of Old Distributor Submitted</Form.Label>
            <Form.Check 
              type="switch" 
              id="fullFinalSubmitted" 
              label={fullFinalSubmitted ? "Yes" : "No"}
              checked={fullFinalSubmitted}
              readOnly
            />
          </Form.Group>
          <Button variant="primary" type="submit" >Approved</Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Customerapproval;
