import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../Services/api';

const Deliverylist = ({ GetVibeDelivery }) => {
  const [deliveryData, setDeliveryData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the delivery data when the component mounts
    const fetchData = async () => {
      try {
        const data = await api.GetVibeDelivery();
        setDeliveryData(data);
      } catch (error) {
        console.error("Error fetching delivery data:", error.message);
      }
    };

    fetchData();
  }, [GetVibeDelivery]);

  const handleAddClick = () => {
    navigate('/Deliverynote');
  };

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Delivery Note</h2>
        <button className="btn btn-primary" onClick={handleAddClick}>
          Add
        </button>
      </div>
      <div className="card">
        <div className="card-body">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Delivery No</th>
                <th>Customer</th>
                <th>Company</th>
                <th>Sales No</th>
                <th>Godown</th>
                <th>Date</th>
                <th>Grand Total</th>
              </tr>
            </thead>
            <tbody>
              {deliveryData.length > 0 ? (
                deliveryData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.d_no}</td>
                    <td>{item.customer}</td>
                    <td>{item.company}</td>
                    <td>{item.sales_no}</td>
                    <td>{item.godown}</td>
                    <td>{item.date || 'N/A'}</td>
                    <td>{item.grandtotal}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Deliverylist;
