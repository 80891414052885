import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../Services/api";

function Receiptlist() {
  const [receiptList, setReceiptList] = useState([]);
  const navigate = useNavigate(); // To handle navigation

  // Fetch receipt list from the API
  useEffect(() => {
    const fetchReceiptList = async () => {
      try {
        const data = await api.GetVibeReceiptlist();
        setReceiptList(data);
      } catch (error) {
        console.error("Error fetching receipt list:", error.message);
      }
    };

    fetchReceiptList();
  }, []);

  // Handle click on ADD button
  const handleAddClick = () => {
    navigate("/Receipt"); // Redirect to the new component route
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Receipt Voucher</h2>
        <button className="btn btn-primary" onClick={handleAddClick}>
          ADD
        </button>
      </div>
      <div className="card shadow mt-3">
        <div className="card-body">
          {receiptList.length > 0 ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th>Receipt Date</th>
                  <th>Company</th>
                  <th>Ledger</th>
                  <th>Godown</th>
                </tr>
              </thead>
              <tbody>
                {receiptList.map((receipt) => (
                  <tr key={receipt.id}>
                    {/* <td>{receipt.id}</td> */}
                    <td>{receipt.expendituredate}</td>
                    <td>{receipt.company}</td>
                    <td>{receipt.ledger}</td>
                    <td>{receipt.godown}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No receipts found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Receiptlist;
