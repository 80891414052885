import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate } from "react-router-dom";
import api from "../Services/api";

function StockItem() {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [stockItem, setStockItem] = useState('');
  const [units, setUnits] = useState('');
  const [calculations, setCalculations] = useState('');
  const [taxability, setTaxability] = useState('');
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [igst, setIgst] = useState(0);
  const [hsn, setHsn] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [rate, setRate] = useState(0);
  const [amount, setAmount] = useState(0);
  const [maintainInBatches, setMaintainInBatches] = useState(false);
  const [useExpiryDate, setUseExpiryDate] = useState(false);
  const [trackManufacturingDate, setTrackManufacturingDate] = useState(false);
  const [bomItems, setBomItems] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [godownOptions, setGodownOptions] = useState([]);
  const [bomName, setBomName] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companyData = await api.GetVibeCompany();
        setCompanies(companyData);
      } catch (error) {
        console.error('Failed to fetch companies:', error);
      }
    };

    const fetchGroups = async () => {
      try {
        const groupData = await api.GetVibeGroupdropdown();
        setGroups(groupData.map(group => ({
          label: group.group,
          id: group.id,
        })));
      } catch (error) {
        console.error('Failed to fetch groups:', error);
      }
    };

    const fetchBOMOptions = async () => {
      try {
        const items = await api.GetVibeItem(); // API to get item options
        const godowns = await api.GetVibeGodown(); // API to get godown options
        setItemOptions(items.map(item => item.item));
        setGodownOptions(godowns.map(godown => godown.godown));
      } catch (error) {
        console.error('Failed to fetch BOM options:', error);
      }
    };

    fetchCompanies();
    fetchGroups();
    fetchBOMOptions();
  }, []);

  const handleSave = async () => {
    const stockData = {
      company: selectedCompany ? selectedCompany.value : '',
      under: selectedGroup ? selectedGroup.id : '',
      stockItem,
      units,
      calculations,
      taxability,
      cgst,
      sgst,
      igst,
      hsn,
      qty: quantity,
      rate,
      amount,
      maintainInBatches,
      useExpiryDate,
      trackManufacturingDate,
      bomName,
      bom: bomItems.map(bomItem => ({
        item: bomItem.item,
        godown: bomItem.godown,
        quantity: bomItem.quantity
      }))
    };

    try {
      const response = await api.PostLedgerStockItem(stockData);
      console.log('Stock item saved successfully:', response);
      navigate('/Stocklist');
    } catch (error) {
      console.error('Failed to save stock item:', error.message);
    }
  };

  const handleCancel = () => {
    navigate('/Stocklist');
  };

  const addBOMItem = () => {
    setBomItems([...bomItems, { item: '', godown: '', quantity: 0 }]);
  };

  const handleBOMItemChange = (index, field, value) => {
    const updatedBomItems = [...bomItems];
    updatedBomItems[index][field] = value;
    setBomItems(updatedBomItems);
  };

  const removeBOMItem = (index) => {
    setBomItems(bomItems.filter((_, i) => i !== index));
  };

  return (
    <div className="container mt-4">
      <h2>Stock Item Creation</h2>
      <div className="card shadow mt-3">
        <div className="card-body">
          {/* Company Label */}
          <div className="mb-3 row">
            <label htmlFor="company" className="col-md-2 col-form-label">
              Company
            </label>
            <div className="col-md-4">
              <Typeahead
                id="company"
                options={companies.map(company => ({ label: company.company, value: company.id }))}
                placeholder="Choose a company..."
                labelKey="label"
                onChange={(selected) => setSelectedCompany(selected[0])}
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="group" className="col-md-2 col-form-label">
              Under
            </label>
            <div className="col-md-4">
              <Typeahead
                id="group"
                options={groups}
                placeholder="Choose a group..."
                labelKey="label"
                onChange={(selected) => setSelectedGroup(selected[0])} // Set selectedGroup here
                selected={selectedGroup ? [selectedGroup] : []}
              />
            </div>
          </div>

          {/* Stock Item */}
          <div className="mb-3 row">
            <label htmlFor="stockItem" className="col-md-2 col-form-label">
              Stock Item
            </label>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                id="stockItem"
                value={stockItem}
                onChange={(e) => setStockItem(e.target.value)}
              />
            </div>
          </div>

          {/* Units */}
          <div className="mb-3 row">
            <label htmlFor="units" className="col-md-2 col-form-label">
              Units
            </label>
            <div className="col-md-4">
              <Typeahead
                id="units"
                options={['Kgs', 'Pcs','Bags','Bale','Bundles','Buckles','Billion Of Units','Box','Bottles','Bunches','Cans','Cubic Meters','Cubic Centimeters','Centimeters','Cartons','Dozens','Drums','Great Gross','Grammes','Gross','Gross Yards','Kilograms','Kiloliter','Kilometer','Liters','Mililiters','Meters','Metric Ton','Numbers','Others','Packs','Pieces','Pairs','Quintal','Rolls','Sets','Square feet','Square Meters','Square Yards','Tablets','Ten Gross','Thousands','Tonnes','Tubes','US Gallons','Units','Yards']}
                placeholder="Choose units..."
                onChange={(selected) => setUnits(selected[0])}
              />
            </div>
          </div>

          {/* GST Rate Header */}
          <h5 className="mt-4">GST Rate</h5>

          <div className="mb-3 row">
            <label htmlFor="calculations" className="col-md-2 col-form-label">
              Calculation Type
            </label>
            <div className="col-md-4">
              <Typeahead
                id="calculations"
                options={['On Value']}
                placeholder="Choose Calculation Type..."
                onChange={(selected) => setCalculations(selected[0])}
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="taxability" className="col-md-2 col-form-label">
              Taxatbility
            </label>
            <div className="col-md-4">
              <Typeahead
                id="taxability"
                options={['Unknown','Exampt','Nil Rated','Taxable']}
                placeholder="Choose Tax Type..."
                onChange={(selected) => setTaxability(selected[0])}
              />
            </div>
          </div>

          {/* CGST */}
          <div className="mb-3 row">
            <label htmlFor="cgst" className="col-md-2 col-form-label">
              CGST
            </label>
            <div className="col-md-4">
              <input
                type="number"
                className="form-control"
                id="cgst"
                value={cgst}
                onChange={(e) => setCgst(parseFloat(e.target.value))}
              />
            </div>
          </div>

          {/* SGST */}
          <div className="mb-3 row">
            <label htmlFor="sgst" className="col-md-2 col-form-label">
              SGST
            </label>
            <div className="col-md-4">
              <input
                type="number"
                className="form-control"
                id="sgst"
                value={sgst}
                onChange={(e) => setSgst(parseFloat(e.target.value))}
              />
            </div>
          </div>

          {/* IGST */}
          <div className="mb-3 row">
            <label htmlFor="igst" className="col-md-2 col-form-label">
              IGST
            </label>
            <div className="col-md-4">
              <input
                type="number"
                className="form-control"
                id="igst"
                value={igst}
                onChange={(e) => setIgst(parseFloat(e.target.value))}
              />
            </div>
          </div>

          {/* HSN No. */}
          <div className="mb-3 row">
            <label htmlFor="hsn" className="col-md-2 col-form-label">
              HSN No.
            </label>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                id="hsnNo"
                value={hsn}
                onChange={(e) => setHsn(e.target.value)}
              />
            </div>
          </div>


          <div className="mb-3 row">
            <label className="col-md-2 col-form-label">
              Maintain in batches
            </label>
            <div className="col-md-4">
              <input
                type="checkbox"
                checked={maintainInBatches}
                onChange={() => setMaintainInBatches(!maintainInBatches)}
              />
            </div>
          </div>

          {/* Use expiry date */}
          <div className="mb-3 row">
            <label className="col-md-2 col-form-label">
              Use expiry date
            </label>
            <div className="col-md-4">
              <input
                type="checkbox"
                checked={useExpiryDate}
                onChange={() => setUseExpiryDate(!useExpiryDate)}
              />
            </div>
          </div>

          {/* Track date of manufacturing */}
          <div className="mb-3 row">
            <label className="col-md-2 col-form-label">
              Track date of manufacturing
            </label>
            <div className="col-md-4">
              <input
                type="checkbox"
                checked={trackManufacturingDate}
                onChange={() => setTrackManufacturingDate(!trackManufacturingDate)}
              />
            </div>
          </div>

          {/* BOM (Bill of Material) */}
          <h5 className="mt-4">BOM (Bill of Material)</h5>
          <div className="mb-3 row">
            <label htmlFor="bomName" className="col-md-2 col-form-label">
              Name of BOM
            </label>
            <div className="col-md-4">
                <input
                 type="text"
                 className="form-control"
                 id="bomName"
                 value={bomName}
                 onChange={(e) => setBomName(e.target.value)}
               />
            </div>
          </div>
          <button className="btn btn-primary mb-3" onClick={addBOMItem}>
            +
          </button>
          {bomItems.map((item, index) => (
            <div key={index} className="mb-3 row">
              <div className="col-md-3">
                <Typeahead
                  id={`bomItem-${index}`}
                  options={itemOptions}
                  placeholder="Select Item"
                  value={item.item}
                  onChange={(selected) => handleBOMItemChange(index, 'item', selected[0] || '')}
                />
              </div>
              <div className="col-md-3">
                <Typeahead
                  id={`bomGodown-${index}`}
                  options={godownOptions}
                  placeholder="Select Godown"
                  value={item.godown}
                  onChange={(selected) => handleBOMItemChange(index, 'godown', selected[0] || '')}
                />
              </div>
              <div className="col-md-2">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Quantity"
                  value={item.quantity}
                  onChange={(e) => handleBOMItemChange(index, 'quantity', parseFloat(e.target.value))}
                />
              </div>
              <div className="col-md-2">
                <button className="btn btn-danger" onClick={() => removeBOMItem(index)}>
                  Remove
                </button>
              </div>
            </div>
          ))}

          {/* Opening Balance Header */}
          <h5 className="mt-4">Opening Balance</h5>

          {/* Quantity */}
          <div className="mb-3 row">
            <label htmlFor="quantity" className="col-md-2 col-form-label">
              Quantity
            </label>
            <div className="col-md-4">
              <input
                type="number"
                className="form-control"
                id="quantity"
                value={quantity}
                onChange={(e) => setQuantity(parseFloat(e.target.value))}
              />
            </div>
          </div>

          {/* Rate */}
          <div className="mb-3 row">
            <label htmlFor="rate" className="col-md-2 col-form-label">
              Rate
            </label>
            <div className="col-md-4">
              <input
                type="number"
                className="form-control"
                id="rate"
                value={rate}
                onChange={(e) => setRate(parseFloat(e.target.value))}
              />
            </div>
          </div>

          {/* Amount */}
          <div className="mb-3 row">
            <label htmlFor="amount" className="col-md-2 col-form-label">
              Amount
            </label>
            <div className="col-md-4">
              <input
                type="number"
                className="form-control"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(parseFloat(e.target.value))}
              />
            </div>
          </div>

          {/* Save and Cancel buttons */}
          <div className="mt-4">
            <button className="btn btn-success me-2" onClick={handleSave}>
              Save
            </button>
            <button className="btn btn-secondary" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockItem;
