import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from "../Services/api";
import { useNavigate } from "react-router-dom";

function Payment() {
  const [expenditureDate, setExpenditureDate] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedCashBank, setSelectedCashBank] = useState([]);
  const [selectedLedgerName, setSelectedLedgerName] = useState([]);
  const [selectedFactoryGodown, setSelectedFactoryGodown] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [factoryGodownOptions, setFactoryGodownOptions] = useState([]);
  const [ledgerOptions, setledgerOptions] = useState([]);
  const [cashBankOptions, setCashBankOptions] = useState([
    { label: 'Cash', value: 'cash' },
    { label: 'Bank', value: 'bank' }
  ]);
  const navigate = useNavigate();

  // Fetch companies
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companyData = await api.GetVibeCompany();
        setCompanyOptions(companyData.map(company => ({
          label: company.company,
          value: company.id
        })));
      } catch (error) {
        console.error('Failed to fetch companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  // Fetch factory/godown
  useEffect(() => {
    const fetchFactoryGodowns = async () => {
      try {
        const godownData = await api.GetVibeGodown();
        setFactoryGodownOptions(godownData.map(godown => ({
          label: godown.godown,
          value: godown.id
        })));
      } catch (error) {
        console.error('Failed to fetch factory/godown data:', error);
      }
    };

    fetchFactoryGodowns();
  }, []);

  // Fetch ledgers
  useEffect(() => {
    const fetchLedgers = async () => {
      try {
        const ledgerData = await api.GetVibeLedgers();
        setledgerOptions(ledgerData.map(ledger => ({
          label: ledger.ledgers,
          value: ledger.id
        })));
      } catch (error) {
        console.error('Failed to fetch ledgers:', error);
      }
    };

    fetchLedgers();
  }, []);

  const handleSave = async () => {
    const payload = {
      expendituredate: expenditureDate,
      voucherno: "VN123456", // You can generate or fetch this dynamically
      amount: parseFloat(expenseAmount), // Convert to number
      remarks: remarks,
      company: selectedCompany[0]?.value || "", // Extract the selected company ID
      ledger: selectedLedgerName[0]?.value || "", // Extract the selected ledger ID
      godown: selectedFactoryGodown[0]?.value || "", // Extract the selected godown ID
      billtype: selectedCashBank[0]?.value || "" // Extract the selected bill type
    };

    try {
      // Call the API function to save the data
      const response = await api.PostExpenses(payload);
      console.log("Data saved successfully:", response);

      // Clear form after saving
      setExpenditureDate("");
      setExpenseAmount("");
      setRemarks("");
      setSelectedCompany([]);
      setSelectedCashBank([]);
      setSelectedLedgerName([]);
      setSelectedFactoryGodown([]);

      // Redirect to ExpensesList after saving
      navigate('/expenselist');
    } catch (error) {
      console.error("Error saving data:", error.message);
    }
  };

  const handleCancel = () => {
    navigate('/expenselist');
  };

  return (
    <div className="container mt-4">
      <h2>Expense Voucher</h2>
      <div className="card shadow mt-3">
        <div className="card-body">
          <div className="row mb-3">
            <label htmlFor="expenditureDate" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Expenditure Date
            </label>
            <div className="col-md-4">
              <input
                type="date"
                className="form-control"
                id="expenditureDate"
                value={expenditureDate}
                onChange={(e) => setExpenditureDate(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="company" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Company
            </label>
            <div className="col-md-4">
              <Typeahead
                id="company"
                options={companyOptions}
                placeholder="Choose a company..."
                onChange={(selected) => setSelectedCompany(selected)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="cashBank" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Cash/Bank
            </label>
            <div className="col-md-4">
              <Typeahead
                id="cashBank"
                options={cashBankOptions}
                placeholder="Choose cash/bank..."
                defaultSelected={[cashBankOptions[0]]} // Set default selection
                onChange={(selected) => setSelectedCashBank(selected)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="ledgerName" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Ledger Name
            </label>
            <div className="col-md-4">
              <Typeahead
                id="ledgers"
                options={ledgerOptions} // Add options here
                placeholder="Choose a ledger name..."
                onChange={(selected) => setSelectedLedgerName(selected)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="factoryGodown" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Factory/Godown
            </label>
            <div className="col-md-4">
              <Typeahead
                id="factoryGodown"
                options={factoryGodownOptions}
                placeholder="Choose a factory/godown..."
                onChange={(selected) => setSelectedFactoryGodown(selected)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="expenseAmount" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Expense Amount
            </label>
            <div className="col-md-4">
              <input
                type="number"
                className="form-control"
                id="expenseAmount"
                value={expenseAmount}
                onChange={(e) => setExpenseAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="remarks" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Remarks
            </label>
            <div className="col-md-4">
              <textarea
                className="form-control"
                id="remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div>
          <button className="btn btn-primary" onClick={handleSave}>
            Save
          </button> &nbsp; &nbsp;
          <button className="btn btn-primary" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default Payment;
