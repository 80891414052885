import React, { useState } from "react";
import { Link } from "react-router-dom";


const pages = [
  {
    name: "Dashboard",
    icon: "nav-icon fas fa-tachometer-alt",
    route: "/dashboard",
  },
  {
    name: "Masters",
    icon: "nav-icon fas fa-cogs",
    children: [
      {
        name: "Customer Details",
        icon: "fas fa-map-marker-alt",
        route: "/Customermaster",
      },
      {
        name: "Customer Form",
        icon: "fas fa-map-marker-alt",
        route: "/Customerform",
      },
      {
        name: "Additional Customer Details",
        icon: "fas fa-warehouse",
        route: "/CustomerDetail",
      },
      // {
      //   name: "Ledger Group",
      //   icon: "fas fa-warehouse",
      //   route: "/Grouplist",
      // },
      // {
      //   name: "Ledger Master",
      //   icon: "fas fa-warehouse",
      //   route: "/Ledgerlist",
      // },
      // {
      //   name: "Stock Item Master",
      //   icon: "fas fa-warehouse",
      //   route: "/Stocklist",
      // },
    ],
  },
  // {
  //   name: "Account and Billings",
  //   icon: "nav-icon fas fa-book",
  //   children: [
  //     {
  //       name: "Payment",
  //       icon: "fas fa-file-invoice-dollar",
  //       route: "/Expenselist",
  //     },
  //     {
  //       name: "Receipt",
  //       icon: "fas fa-sign-in-alt",
  //       route: "/Receiptlist",
  //     },
  //     {
  //       name: "Delivery Note",
  //       icon: "fas fa-sign-in-alt",
  //       route: "/Deliverylist",
  //     },
  //     {
  //       name: "Sales Invoice",
  //       icon: "fas fa-sign-in-alt",
  //       route: "/SalesInvoice",
  //     },
  //   ],
  // },
  {
    name: "Delivery",
    icon: "nav-icon fas fa-eye",
    children: [
      { name: "Sales Order", icon: "fas fa-user-cog", route: "/Saleslist" },
      { name: "Maps", icon: "fas fa-file-alt", route: "/Mags" },
      // { name: "Manufacturing", icon: "fas fa-file-alt", route: "/Manufacturing" },
      // { name: "Purchase Invoice", icon: "fas fa-file-alt", route: "/PurchaseInvoice" },
    ],
  },
  // {
  //   name: "Approval",
  //   icon: "nav-icon fas fa-external-link-alt",
  //   children: [
  //     {
  //       name: "Customer Profile",
  //       icon: "fas fa-truck-loading",
  //       route: "/CustomerApproved",
  //     },
  //     {
  //       name: "Expenses",
  //       icon: "fas fa-truck-loading",
  //       route: "/ExpensesApproved",
  //     },
  //   ],
  // },
  // {
  //   name: "Financial Reporting",
  //   icon: "nav-icon fas fa-external-link-alt",
  //   children: [
  //     {
  //       name: "Sales and Revenue Report",
  //       icon: "fas fa-truck-loading",
  //       route: "/ReportFormate",
  //     },
  //     {
  //       name: "Profit and Loss Statement",
  //       icon: "fas fa-truck-loading",
  //       route: "/SOprice",
  //     },
  //     {
  //       name: "Tax Reporting",
  //       icon: "fas fa-truck-loading",
  //       route: "/Oredrdelivery",
  //     },
  //     {
  //       name: "Expenses",
  //       icon: "fas fa-truck-loading",
  //       route: "/Oredrdelivery",
  //     },
  //     {
  //       name: "Accounts Receivable and Payables",
  //       icon: "fas fa-truck-loading",
  //       route: "/Oredrdelivery",
  //     },
  //     {
  //       name: "Dispatch Pending Report",
  //       icon: "fas fa-truck-loading",
  //       route: "/Oredrdelivery",
  //     },
  //   ],
  // },
];

const SideBar = () => {
  const [expandedMenu, setExpandedMenu] = useState(null);

  const toggleMenu = (index) => {
    setExpandedMenu(expandedMenu === index ? null : index);
  };

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{
      background: 'linear-gradient(45deg, rgba(173, 216, 230, 0.8), rgba(0, 0, 0, 0.8))', // Light blue and black gradient background
      boxShadow: '0 0 20px rgba(173, 216, 230, 0.6)', // Light blue glow
    }}>
     <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {pages.map((page, index) => (
              <li
                className={`nav-item ${page.children ? "has-treeview" : ""}`}
                key={index}
              >
                {page.children ? (
                  <React.Fragment>
                    <div
                      className="nav-link"
                      onClick={() => toggleMenu(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <i className={`nav-icon ${page.icon}`}></i>
                      <p>
                        {page.name}
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </div>
                    <ul
                      className={`nav nav-treeview ${
                        expandedMenu === index ? "menu-open" : ""
                      }`}
                      style={{
                        display: expandedMenu === index ? "block" : "none",
                      }}
                    >
                      {page.children.map((child, childIndex) => (
                        <li className="nav-item" key={childIndex}>
                          <Link to={child.route} className="nav-link pl-4">
                            <i className={`nav-icon ${child.icon}`}></i>
                            <p>{child.name}</p>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                ) : (
                  <Link to={page.route} className="nav-link">
                    <i className={`nav-icon ${page.icon}`}></i>
                    <p>{page.name}</p>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;
