/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import SideBar from './SideBar';

const AuthenticatedLayout = ({ children }) => {
  return (
    <div className="wrapper">
      <Header />
      <SideBar />
      <div className="content-wrapper">
        <div className="content">
          {children} {/* This will render the content of the authenticated page */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

// PropTypes validation for children
AuthenticatedLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticatedLayout;
