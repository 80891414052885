const Footer = () => {
  return (
    <footer className="main-footer" style={{
      background: 'linear-gradient(45deg, rgba(0,0,255,0.8), rgba(0,0,0,0.8))', // Blue and black gradient background
      boxShadow: '0 0 20px rgba(0,0,255,0.6)', // Blue glow
      color: 'white', // Text color to contrast with the dark background
      padding: '1rem',
      textAlign: 'center',
    }}>
      <strong>N.Sanjivan @2024 copyrights</strong>
      <div className="float-right d-none d-sm-inline-block"></div>
    </footer>
  );
};

export default Footer;
