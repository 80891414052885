import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment-timezone';

Chart.register(...registerables, zoomPlugin);

const Dashboard = () => {
  const [currentTime, setCurrentTime] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
  const [timeZone, setTimeZone] = useState(moment.tz.guess());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format('YYYY-MM-DD HH:mm:ss'));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const dashboardStyle = {
    backgroundColor: "#000",
    backgroundImage: "radial-gradient(circle, #800080, #000)",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    textAlign: "center",
  };

  const cardStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    border: "none",
    color: "white",
    padding: "20px",
    margin: "10px",
    borderRadius: "10px",
  };

  const barData = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Sales",
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.4)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: [65, 59, 80, 81, 56],
      },
    ],
  };

  const barOptions = {
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          mode: 'x', // Only zoom along the x-axis
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
  };

  const lineData = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Revenue",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [65, 59, 80, 81, 56],
      },
    ],
  };

  const pieData = {
    labels: ["Red", "Blue", "Yellow"],
    datasets: [
      {
        data: [300, 50, 100],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const doughnutData = {
    labels: ["Green", "Purple", "Orange"],
    datasets: [
      {
        data: [200, 150, 100],
        backgroundColor: ["#4BC0C0", "#9966FF", "#FF9F40"],
        hoverBackgroundColor: ["#4BC0C0", "#9966FF", "#FF9F40"],
      },
    ],
  };

  return (
    <div style={dashboardStyle}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={cardStyle}>
              <div className="card-body">
                <h5 className="card-title">Current Time and Time Zone</h5>
                <p>{currentTime}</p>
                <p>Time Zone: {timeZone}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card" style={cardStyle}>
              <div className="card-body">
                <h5 className="card-title">Sales Bar Chart with Zoom & Pan</h5>
                <Bar data={barData} options={barOptions} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card" style={cardStyle}>
              <div className="card-body">
                <h5 className="card-title">Revenue Line Chart</h5>
                <Line data={lineData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
