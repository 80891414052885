import "@fortawesome/fontawesome-free/css/all.min.css";
import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/dist/js/adminlte.min.js";
import "react-toastify/dist/ReactToastify.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./components/Services/ProtectedRoute";
import Profile from './components/pages/profile';
import Dashboard from './components/pages/Dashboard';
import LoginPage from './components/login';
import AccountsReceivables from './components/pages/AccountsReceivable';
import CustomerDetail from './components/pages/CustomerDetail';
import CustomerList from './components/pages/Customermaster';
import Deliverynote from './components/pages/Deliverynote';
import DispatchPending from './components/pages/DispatchPending';
import ExpensesApproval from './components/pages/Expenses';
import Expensesreport from './components/pages/Expensesreport';
import Mags from './components/pages/Mags';
import Payment from './components/pages/Payment';
import Profitloss from './components/pages/Profitloss';
import Purchases from './components/pages/Purchase';
import Receipt from './components/pages/Receipt';
import Salesbill from './components/pages/Salesbill';
import Salesinvoice from './components/pages/SalesInvoice';
import Customerformdata from "./components/pages/Customerform";
import ExpenseForm from "./components/pages/ExpensesApproved";
import Customerapproval from "./components/pages/CustomerApproved";
import ExpenseTable from "./components/pages/Expenselist";
import Ledger from "./components/pages/Ledger";
import StockItem from "./components/pages/Stockitem";
import Receiptlist from "./components/pages/Receiptlist";
import Ledgerlist from "./components/pages/Ledgerlist";
import Stocklist from "./components/pages/Stocklist";
import LedgerGroupList from "./components/pages/Grouplist";
import GroupForm from "./components/pages/GroupAdd";
import SalesBillCard from "./components/pages/Saleslist";
import Deliverylist from "./components/pages/Deliverylist";
import Manufacturing from "./components/pages/Manufacturing";
import Purchaseinvoice from "./components/pages/PurchaseInvoice";

const routes = [
  { path: "/", component: LoginPage, exact: true, protected: false },
  { path: "/profile", component: Profile, protected: true },
  { path: "/dashboard", component: Dashboard, protected: true },
  { path: "/Ledger", component: Ledger, protected: true },
  { path: "/Grouplist", component: LedgerGroupList, protected: true },
  { path: "/Deliverylist", component: Deliverylist, protected: true },
  { path: "/Manufacturing", component: Manufacturing, protected: true },
  { path: "/PurchaseInvoice", component: Purchaseinvoice, protected: true },
  { path: "/Saleslist", component: SalesBillCard, protected: true },
  { path: "/GroupAdd", component: GroupForm, protected: true },
  { path: "/Ledgerlist", component: Ledgerlist, protected: true },
  { path: "/Stockitem", component: StockItem, protected: true },
  { path: "/Stocklist", component: Stocklist, protected: true },
  { path: "AccountsReceivable", component: AccountsReceivables, protected: true },
  { path: "/ExpensesApproved", component: ExpenseForm, protected: true },
  { path: "/CustomerApproved", component: Customerapproval, protected: true },
  { path: "/CustomerDetail", component: CustomerDetail, protected: true },
  { path: "/Customermaster", component: CustomerList, protected: true },
  { path: "/Receiptlist", component: Receiptlist, protected: true },

  { path: "/Deliverynote", component: Deliverynote, protected: true },
  { path: "/DispatchPending", component: DispatchPending, protected: true },
  { path: "/Expenses", component: ExpensesApproval, protected: true },
  { path: "/Expensesreport", component: Expensesreport, protected: true },
  { path: "/Mags", component: Mags, protected: true },
  { path: "/Payment", component: Payment, protected: true },
  { path: "/Profitloss", component: Profitloss, protected: true },
  { path: "/Purchase", component: Purchases, protected: true },
  { path: "/Receipt", component: Receipt, protected: true },
  { path: "/Salesbill", component: Salesbill, protected: true },
  { path: "/SalesInvoice", component: Salesinvoice, protected: true },
  { path: "/Customerform", component: Customerformdata, protected: true },
  { path: "/Expenselist", component: ExpenseTable, protected: true },


];

function App() {
  const token = localStorage.getItem("token");

  return (
    <div className="wrapper text-xs">
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/"
            element={token ? <Navigate to="/dashboard" /> : <LoginPage />}
          />

          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                route.protected ? (
                  <ProtectedRoute token={token}>
                    <route.component />
                  </ProtectedRoute>
                ) : (
                  <route.component />
                )
              }
            />
          ))}

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
