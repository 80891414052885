import React from "react";
import { Card, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const AccountsReceivables = () => {
  return (
    <Container className="mt-4">
      <header className="mb-4">
        <h1>Accounts Receivables and Payables</h1>
      </header>
      <Card>
        <Card.Body></Card.Body>
      </Card>
    </Container>
  );
};

export default AccountsReceivables;
