import React, { useState, useEffect } from 'react';
// Assuming the API function is imported from your API reference file
import api from "../Services/api";
import { Typeahead } from 'react-bootstrap-typeahead';


const DistributorForm = () => {
  const [formData, setFormData] = useState({
    customerid: '',
    distributorshipname: '',
    proprietorname: '',
    businessaddress: '',
    businessphone: '',
    residenceaddress: '',
    residencephone: '',
    accountholdername: '',
    firmorproprietor: '',
    accountnumber: '',
    accounttype: '',
    bankname: '',
    bankaddress: '',
    gstno: '',
    panno: '',
    hasbeveragesnacksexperience: false,
    experienceperiod: '',
    areaofoperation: '',
    areaofinterestfrom: '',
    areaofinterestto: '',
    citytown: '',
    district: '',
    salespotential: '',
    retailoutlets: '',
    shopinarea: false,
    shopownership: '',
    additionalmanpower: false,
    numberofadditionalmanpower: '',
    distributionvehicle: false,
    numberofvehicles: '',
    typeofvehicles: '',
    homedeliveryarrangement: false,
    homedeliverydetails: '',
  });

  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const data = await api.GetVibeCustomer();
        console.log("Fetched customers:", data); // Debugging line
        setCustomers(data); // Assuming data is an array of customers
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    fetchCustomers();
  }, []);
  const handleCustomerChange = (selected) => {
    setFormData({
      ...formData,
      customerid: selected.length > 0 ? selected[0].id : '',
    });
  };

  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const payload = {
      additional: [
        {
          ...formData,
          salespotential: parseFloat(formData.salespotential) || 0,
        },
      ],
    };

    try {
      const response = await api.PostVibeadditionalCustomer(payload);
      setSubmissionStatus({ success: true, message: 'Data submitted successfully!' });
      console.log('API response:', response);
    } catch (error) {
      setSubmissionStatus({ success: false, message: error.message });
      console.error('Submission error:', error);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Customer Additional Detail's</h2>
      <div className="card shadow mt-3">
        <div className="card-body">
          <form onSubmit={handleSave}>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Customer ID:</label>
                <Typeahead
                  id="customer-typeahead"
                  labelKey="name"
                  options={customers}
                  placeholder="Select a customer..."
                  onChange={handleCustomerChange}
                  selected={customers.filter(customer => customer.id === formData.customerid)}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Distributorship Name:</label>
                <input
                  type="text"
                  className="form-control"
                  name="distributorshipname"
                  value={formData.distributorshipname}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Proprietor Name:</label>
                <input
                  type="text"
                  className="form-control"
                  name="proprietorname"
                  value={formData.proprietorname}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Business Address:</label>
                <input
                  type="text"
                  className="form-control"
                  name="businessaddress"
                  value={formData.businessaddress}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Business Phone:</label>
                <input
                  type="text"
                  className="form-control"
                  name="businessphone"
                  value={formData.businessphone}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Residence Address:</label>
                <input
                  type="text"
                  className="form-control"
                  name="residenceaddress"
                  value={formData.residenceaddress}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Residence Phone:</label>
                <input
                  type="text"
                  className="form-control"
                  name="residencephone"
                  value={formData.residencephone}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Account Holder Name:</label>
                <input
                  type="text"
                  className="form-control"
                  name="accountholdername"
                  value={formData.accountholdername}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Firm or Proprietor:</label>
                <input
                  type="text"
                  className="form-control"
                  name="firmorproprietor"
                  value={formData.firmorproprietor}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Account Number:</label>
                <input
                  type="text"
                  className="form-control"
                  name="accountnumber"
                  value={formData.accountnumber}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Account Type:</label>
                <input
                  type="text"
                  className="form-control"
                  name="accounttype"
                  value={formData.accounttype}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Bank Name:</label>
                <input
                  type="text"
                  className="form-control"
                  name="bankname"
                  value={formData.bankname}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Bank Address:</label>
                <input
                  type="text"
                  className="form-control"
                  name="bankaddress"
                  value={formData.bankaddress}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">GST No:</label>
                <input
                  type="text"
                  className="form-control"
                  name="gstno"
                  value={formData.gstno}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">PAN No:</label>
                <input
                  type="text"
                  className="form-control"
                  name="panno"
                  value={formData.panno}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Has Beverages & Snacks Experience:</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="hasbeveragesnacksexperience"
                  checked={formData.hasbeveragesnacksexperience}
                  onChange={handleChange}
                />
              </div>
            </div>

            {formData.hasbeveragesnacksexperience && (
              <>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">Experience Period:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="experienceperiod"
                      value={formData.experienceperiod}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Area of Operation:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="areaofoperation"
                      value={formData.areaofoperation}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Area of Interest (From):</label>
                <input
                  type="text"
                  className="form-control"
                  name="areaofinterestfrom"
                  value={formData.areaofinterestfrom}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Area of Interest (To):</label>
                <input
                  type="text"
                  className="form-control"
                  name="areaofinterestto"
                  value={formData.areaofinterestto}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">City/Town:</label>
                <input
                  type="text"
                  className="form-control"
                  name="citytown"
                  value={formData.citytown}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">District:</label>
                <input
                  type="text"
                  className="form-control"
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Sales Potential (INR):</label>
                <input
                  type="number"
                  className="form-control"
                  name="salespotential"
                  value={formData.salespotential}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Retail Outlets:</label>
                <input
                  type="number"
                  className="form-control"
                  name="retailoutlets"
                  value={formData.retailoutlets}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Shop in Area:</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="shopinarea"
                  checked={formData.shopinarea}
                  onChange={handleChange}
                />
              </div>
              {formData.shopinarea && (
                <div className="col-md-6">
                  <label className="form-label">Ownership:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="shopownership"
                    value={formData.shopownership}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Additional Manpower:</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="additionalmanpower"
                  checked={formData.additionalmanpower}
                  onChange={handleChange}
                />
              </div>
              {formData.additionalmanpower && (
                <div className="col-md-6">
                  <label className="form-label">Number of Additional Manpower:</label>
                  <input
                    type="number"
                    className="form-control"
                    name="numberofadditionalmanpower"
                    value={formData.numberofadditionalmanpower}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Distribution Vehicle:</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="distributionvehicle"
                  checked={formData.distributionvehicle}
                  onChange={handleChange}
                />
              </div>
              {formData.distributionvehicle && (
                <>
                  <div className="col-md-6">
                    <label className="form-label">Number of Vehicles:</label>
                    <input
                      type="number"
                      className="form-control"
                      name="numberofvehicles"
                      value={formData.numberofvehicles}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Type of Vehicles:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="typeofvehicles"
                      value={formData.typeofvehicles}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Home Delivery Arrangement:</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="homedeliveryarrangement"
                  checked={formData.homedeliveryarrangement}
                  onChange={handleChange}
                />
              </div>
              {formData.homedeliveryarrangement && (
                <div className="col-md-6">
                  <label className="form-label">Home Delivery Details:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="homedeliverydetails"
                    value={formData.homedeliverydetails}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>

            <button type="submit" className="btn btn-primary">Save</button>
          </form>
          {submissionStatus && (
            <div className={`alert mt-3 ${submissionStatus.success ? 'alert-success' : 'alert-danger'}`}>
              {submissionStatus.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DistributorForm;
