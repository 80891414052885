import React, { useState, useEffect } from "react";
import { Card, Container, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../Services/api"; // Adjust the path as needed

// Define BASE_URL if not already defined in the api.js
// Replace with your actual base URL

// API function to fetch distributors
// const GetDistributor = async () => {
//   try {
//     const response = await api.get(`${BASE_URL}/VibeAdditionalCustomer`);
//     return response.data;
//   } catch (error) {
//     throw new Error(`Error getting Distributor: ${error.message}`);
//   }
// };

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.GetDistributor();
        setCustomers(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container fluid className="mt-4" style={{ background: 'linear-gradient(45deg, rgba(173, 216, 230, 0.8), rgba(0, 0, 0, 0.8))', minHeight: '100vh' }}>
      <header className="mb-4">
        <h1 style={{ color: '#ffffff' }}>Customers</h1>
      </header>
      <Card style={{
        background: 'rgba(0, 0, 0, 0.5)', // Slightly transparent black background for the card
        boxShadow: '0 0 20px rgba(173, 216, 230, 0.6)', // Light blue glow
        border: 'none', // Remove default card border
      }}>
        <Card.Body>
          <Table striped bordered hover variant="dark" style={{ color: '#ffffff' }}>
            <thead>
              <tr>
                <th>Code</th>
                <th>Customer Name</th>
                <th>Distributor</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer) => (
                <tr key={customer.id}>
                  <td>{customer.code}</td>
                  <td>{customer.customers}</td>
                  <td>{customer.distributor}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CustomerList;
