import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../Services/api'; // Adjust the path as necessary

function GroupForm() {
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [group, setGroup] = useState('');
  const [selectedUnder, setSelectedUnder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await api.GetVibeCompany();
        const formattedOptions = response.map(company => ({
          label: company.company,
          id: company.id,
        }));
        setCompanyOptions(formattedOptions);
      } catch (error) {
        console.error('Error fetching company options:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  const handleSave = async () => {
    if (!selectedCompany || !group || !selectedUnder) {
      alert('Please fill out all fields before saving.');
      return;
    }

    const groupData = {
      company: selectedCompany[0]?.id || '',
      group: group,
      under: selectedUnder[0] || ''
    };

    try {
      const response = await api.PostVibeGroup(groupData);
      alert('Group saved successfully!');
      navigate('/Grouplist'); // Redirect to group list after save
    } catch (error) {
      console.error('Error saving group:', error);
      alert('Failed to save group. Please try again.');
    }
  };

  const handleCancel = () => {
    navigate('/Grouplist');
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-body">
          {/* Company Label */}
          <div className="mb-3">
            <label htmlFor="company">Company</label>
            <Typeahead
              id="company"
              options={companyOptions}
              placeholder="Choose a company..."
              isLoading={isLoading}
              className="col-md-6"
              labelKey="label"
              onChange={setSelectedCompany}
              selected={selectedCompany}
            />
          </div>

          {/* Group Label */}
          <div className="mb-3">
            <label htmlFor="group">Group</label>
            <input
              type="text"
              id="group"
              className="form-control col-md-6"
              placeholder="Enter group name"
              value={group}
              onChange={(e) => setGroup(e.target.value)}
            />
          </div>

          {/* Under Label */}
          <div className="mb-3">
  <label htmlFor="under">Under</label>
  <input
    type="text"
    id="under"
    className="form-control col-md-6"
    placeholder="Enter role..."
    value={selectedUnder} // Assuming `selectedUnder` is the state holding the input value
    onChange={(e) => setSelectedUnder(e.target.value)} // Updating the state with the input value
  />
</div>


          {/* Save and Cancel Buttons */}
          <div className="mt-4">
            <button className="btn btn-primary me-2" onClick={handleSave}>
              Save
            </button>
            <button className="btn btn-secondary" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupForm;
