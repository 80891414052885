import React, { useEffect, useState } from "react";
import api from "../Services/api";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Administration = () => {
  const [users, setUsers] = useState([]);
  const [currentView, setCurrentView] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newUser, setNewUser] = useState({
    userName: "",
    email: "",
    mobileNumber: "",
  });

  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [parties, setParties] = useState([]);
  const [godowns, setGodowns] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await api.GetUsers();
        setUsers(userData);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
    fetchParty();
    fetchGodown();
  }, []);

  const fetchParty = async () => {
    try {
      const partyData = await api.partyWarehouse();
      setParties(partyData);
    } catch (error) {
      console.error("Error fetching party:", error);
    }
  };

  const fetchGodown = async () => {
    try {
      const godownData = await api.GodownPWC();
      setGodowns(godownData);
    } catch (error) {
      console.error("Error fetching Godown:", error);
    }
  };

  const handleResetPassword = async () => {
    if (email && password) {
      const passwordData = { email, password };
      try {
        await api.PostPassword(passwordData);
        alert("Password reset successfully");
        setCurrentView("");
      } catch (error) {
        console.error("Error resetting password:", error);
        alert("Failed to reset password");
      }
    } else {
      alert("Please enter both email and password");
    }
  };

  const handleAddUser = async () => {
    try {
      await api.PostUser(newUser);
      alert("User added successfully");
      setCurrentView("");

      const userData = await api.GetUsers();
      setUsers(userData);
    } catch (error) {
      console.error("Error adding user:", error);
      alert("Failed to add user");
    }
  };

  const handleEditUser = (user) => {
    setCurrentView("edit");
    setNewUser({
      id: user.id,
      userName: user.userName,
      email: user.email,
      mobileNumber: user.mobileNumber,
    });
  };

  const updateUserData = async () => {
    try {
      await api.updateUser({
        id: newUser.id,
        userName: newUser.userName,
        email: newUser.email,
        mobileNumber: newUser.mobileNumber,
      });
      alert("User updated successfully");
      setCurrentView("");

      const userData = await api.GetUsers();
      setUsers(userData);
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Failed to update user");
    }
  };

  const handleAssignUser = (user) => {
    setCurrentView("assignUser");
    setNewUser({
      userID: user.userID,
      loginId: user.loginId,
      email: user.email,
      mobile: user.mobile,
      fullName: user.fullName,
      isActive: user.isActive,
    });
  };

  const handleSaveAssignment = async () => {
    const assignData = {
      stockItems: [
        {
          user: newUser.userID,
          assigned: selectedOptions1.map((option, index) => ({
            customer: option.value,
            godown: selectedOptions2[index]?.value || "",
          })),
        },
      ],
    };

    try {
      await api.Assignuser(assignData);
      alert("User assigned successfully");
      setCurrentView("");
    } catch (error) {
      console.error("Error assigning user:", error);
      alert("Failed to assign user");
      window.location.reload();
    }
  };

  const MenuAuthorizationView = () => (
    <div className="card">
      <div className="card-header">
        <h3>Menu Authorization</h3>
      </div>
      <div className="card-body">
        <Form>
          <Form.Group controlId="formMenuPermissions">
            <Form.Label>Menu Permissions</Form.Label>
            <Select
              isMulti
              value={[]}
              onChange={(selectedOptions) =>
                console.log("Selected options:", selectedOptions)
              }
              options={[
                { value: "dashboard", label: "Dashboard" },
                { value: "sales", label: "Sales" },
                { value: "inventory", label: "Inventory" },
                { value: "reports", label: "Reports" },
              ]}
            />
          </Form.Group>
        </Form>
      </div>
      <div className="card-footer">
        <Button variant="light" onClick={() => setCurrentView("")}>
          <i className="fa fa-window-close" aria-hidden="true"></i>
        </Button>
        {/* Add additional buttons or actions if needed */}
      </div>
    </div>
  );

  return (
    <>
      <section className="content-header">
        <h1>Administration</h1>
      </section>
      <section className="content">
        <div className="container-fluid content-container">
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header d-flex justify-content-end">
                  <Button
                    variant="light"
                    className="mr-2"
                    onClick={() => setCurrentView("add")}
                  >
                    <i className="fas fa-plus"></i>
                  </Button>
                  <Button
                    variant="light"
                    onClick={() => setCurrentView("resetPassword")}
                  >
                    <i className="fa fa-key" aria-hidden="true"></i>
                  </Button>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped table-hover text-center table-sm">
                      <thead>
                        <tr>
                          <th scope="col">User Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Mobile</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user) => (
                          <tr key={user.id}>
                            <td>{user.userName}</td>
                            <td>{user.email}</td>
                            <td>{user.mobileNumber}</td>
                            <td>
                              {/* <Button
                                variant="light"
                                onClick={() => handleAssignUser(user)}
                              >
                                <i
                                  className="fa fa-tasks"
                                  aria-hidden="true"
                                ></i>
                              </Button> */}
                              <Button
                                variant="light"
                                onClick={() => handleEditUser(user)}
                              >
                                <i className="fas fa-edit"></i>
                              </Button>{" "}
                              {/* <Button
                                variant="light"
                                onClick={() =>
                                  setCurrentView("menuAuthorization")
                                }
                              >
                                <i className="fa-solid fa-bars"></i>
                              </Button> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {currentView === "resetPassword" && (
                <div className="card">
                  <div className="card-header">
                    <h3>Reset Password</h3>
                  </div>
                  <div className="card-body">
                    <Form>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter new password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="card-footer">
                    <Button variant="light" onClick={() => setCurrentView("")}>
                      <i className="fa fa-window-close" aria-hidden="true"></i>
                    </Button>
                    <Button
                      variant="light"
                      onClick={handleResetPassword}
                      className="ml-2"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              )}

              {currentView === "add" && (
                <div className="card">
                  <div className="card-header">
                    <h3>Add User</h3>
                  </div>
                  <div className="card-body">
                    <Form>
                      <Form.Group controlId="formUserName">
                        <Form.Label>User Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter user name"
                          value={newUser.userName}
                          onChange={(e) =>
                            setNewUser({ ...newUser, userName: e.target.value })
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={newUser.email}
                          onChange={(e) =>
                            setNewUser({ ...newUser, email: e.target.value })
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="formMobileNumber">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter mobile number"
                          value={newUser.mobileNumber}
                          onChange={(e) =>
                            setNewUser({ ...newUser, mobileNumber: e.target.value })
                          }
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="card-footer">
                    <Button variant="light" onClick={() => setCurrentView("")}>
                      <i className="fa fa-window-close" aria-hidden="true"></i>
                    </Button>
                    <Button
                      variant="light"
                      onClick={handleAddUser}
                      className="ml-2"
                    >
                      Add User
                    </Button>
                  </div>
                </div>
              )}

              {currentView === "edit" && (
                <div className="card">
                  <div className="card-header">
                    <h3>Edit User</h3>
                  </div>
                  <div className="card-body">
                    <Form>
                      <Form.Group controlId="formUserName">
                        <Form.Label>User Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter user name"
                          value={newUser.userName}
                          onChange={(e) =>
                            setNewUser({ ...newUser, userName: e.target.value })
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={newUser.email}
                          onChange={(e) =>
                            setNewUser({ ...newUser, email: e.target.value })
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="formMobileNumber">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter mobile number"
                          value={newUser.mobileNumber}
                          onChange={(e) =>
                            setNewUser({ ...newUser, mobileNumber: e.target.value })
                          }
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="card-footer">
                    <Button variant="light" onClick={() => setCurrentView("")}>
                      <i className="fa fa-window-close" aria-hidden="true"></i>
                    </Button>
                    <Button
                      variant="light"
                      onClick={updateUserData}
                      className="ml-2"
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              )}

              {currentView === "assignUser" && (
                <div className="card">
                  <div className="card-header">
                    <h3>Assign User</h3>
                  </div>
                  <div className="card-body">
                    <Form>
                      <Form.Group controlId="formCustomer">
                        <Form.Label>Customer</Form.Label>
                        <Select
                          isMulti
                          options={parties.map((party) => ({
                            value: party.id,
                            label: party.name,
                          }))}
                          onChange={(options) => setSelectedOptions1(options)}
                        />
                      </Form.Group>
                      <Form.Group controlId="formGodown">
                        <Form.Label>Godown</Form.Label>
                        <Select
                          isMulti
                          options={godowns.map((godown) => ({
                            value: godown.id,
                            label: godown.name,
                          }))}
                          onChange={(options) => setSelectedOptions2(options)}
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="card-footer">
                    <Button variant="light" onClick={() => setCurrentView("")}>
                      <i className="fa fa-window-close" aria-hidden="true"></i>
                    </Button>
                    <Button
                      variant="light"
                      onClick={handleSaveAssignment}
                      className="ml-2"
                    >
                      Save Assignment
                    </Button>
                  </div>
                </div>
              )}

              {currentView === "menuAuthorization" && <MenuAuthorizationView />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Administration;
