import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useNavigate } from "react-router-dom";
import api from "../Services/api"; // Make sure to import your API functions
import { FaTrash } from "react-icons/fa"; // Import the trash icon

function NewSalesOrder() {
  const [soDate, setSoDate] = useState("");
  const [customer, setCustomer] = useState("");
  const [company, setCompany] = useState(""); // Added company state
  const [inventory, setInventory] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();

  // Fetch customers and items on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerData = await api.GetVibeCustomer(); // Fetch customer data
        setCustomers(customerData);

        const itemData = await api.GetVibeItem(); // Fetch item data
        setItemDetails(itemData);

            const companyData = await api.GetVibeCompany();
            setCompanies(companyData);
          
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    const payload = {
      sodate: soDate,
      company,
      customer,
      grandtotal: grandTotal,
      inventory,
      selectedItemDetails,
    };

    // Log payload for demonstration purposes
    console.log("Payload to be saved:", payload);

    try {
      const response = await api.PostVibesalesbill(payload);
      console.log("Sales order saved successfully:", response);
      navigate("/Saleslist");
    } catch (error) {
      console.error("Error saving sales order:", error.message);
    }
  };

  const handleInventoryChange = (index, key, value) => {
    const updatedInventory = [...inventory];
    updatedInventory[index][key] = value;
    if (key === "soqty" || key === "cPrice") {
      updatedInventory[index].total = (
        (Number(updatedInventory[index].soqty) || 0) *
        (Number(updatedInventory[index].cPrice) || 0)
      ).toFixed(2);
    }
    setInventory(updatedInventory);
    updateGrandTotal(updatedInventory);
  };

  const updateGrandTotal = (updatedInventory) => {
    const newGrandTotal = updatedInventory
      .reduce((total, item) => total + (Number(item.total) || 0), 0)
      .toFixed(2);
    setGrandTotal(newGrandTotal);
  };

  const handleItemDetailChange = async (itemId) => {
    if (itemId && !selectedItemDetails.includes(itemId)) {
      try {
        const fetchedItem = await api.GetVibeItemById(itemId);
        const newItem = {
          itemCode: fetchedItem.id,
          itemName: fetchedItem.item,
          soqty: 0,
          itemPrice: fetchedItem.amount, // Assuming this is the price list
          cPrice: fetchedItem.amount,
          total: 0,
        };
        setInventory([...inventory, newItem]);
        setSelectedItemDetails([...selectedItemDetails, itemId]);
      } catch (error) {
        console.error("Error fetching item details:", error.message);
      }
    }
  };

  const handleRemoveItem = (index) => {
    const updatedInventory = inventory.filter((_, i) => i !== index);
    setInventory(updatedInventory);
    updateGrandTotal(updatedInventory);
  };

  const handleCancel = () => {
    navigate('/Saleslist');
  };

  return (
    <div className="container mt-4">
      <h2>New Sales Order</h2>
      <div className="card shadow mt-3">
        <div className="card-body">
          <div className="row mb-3">
            <label htmlFor="soDate" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              SO Date
            </label>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                id="soDate"
                value={soDate}
                onChange={(e) => setSoDate(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="company" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Company
            </label>
            <div className="col-md-3">
              <Typeahead
                id="company"
                onChange={(selected) => {
                  setCompany(selected[0]?.id || "");
                }}
                options={companies} // Use fetched data
                labelKey="company" // Ensure this matches the actual data structure
                placeholder="Select Company"
              />
            </div>
          </div>
          <div className="row mb-1">
            <label htmlFor="customer" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Customer
            </label>
            <div className="col-md-3">
              <Typeahead
                id="customer"
                onChange={(selected) => {
                  setCustomer(selected[0]?.id || "");
                }}
                options={customers}
                labelKey="name"
                placeholder="Select Customer"
              />
            </div>
          </div>
          <div className="row mb-1">
            <label
              htmlFor="itemDetails"
              className="col-md-2 col-form-label" style={{ fontSize: '12px' }}
            >
              Item
            </label>
            <div className="col-md-3">
              <Typeahead
                id="itemDetails"
                onChange={(selected) => {
                  handleItemDetailChange(selected[0]?.id || "");
                }}
                options={itemDetails}
                labelKey="item"
                placeholder="Select Item"
              />
            </div>
          </div>
          {/* Table for Sales Order Details */}
          <table className="table mt-4">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Item Name</th>
                <th>SO Qty</th>
                {/* <th>Price List</th> */}
                <th>SO Price</th>
                <th>Total Amt</th>
                <th>Action</th> {/* New column for remove icon */}
              </tr>
            </thead>
            <tbody>
              {inventory.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td> {/* Serial Number */}
                  <td>{item.itemName}</td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={item.soqty}
                      onChange={(e) =>
                        handleInventoryChange(
                          index,
                          "soqty",
                          parseFloat(e.target.value)
                        )
                      }
                      step="0.01"  // Allow decimal values
                      min="0"     // Prevent negative values
                      style={{ width: '100px' }} // Adjust width as needed
                    />
                  </td>
                  {/* <td>{item.itemPrice}</td> */}
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={item.cPrice || 0}
                      onChange={(e) =>
                        handleInventoryChange(
                          index,
                          "cPrice",
                          parseFloat(e.target.value) || 0
                        )
                      }
                      step="0.01"  // Allow decimal values
                      min="0"     // Prevent negative values
                      style={{ width: '100px' }} // Adjust width as needed
                    />
                  </td>
                  <td>{(Number(item.total) || 0).toFixed(2)}</td>
                  <td>
                    <FaTrash 
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => handleRemoveItem(index)} // Handle row removal
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Grand Total and Save Button */}
          <div className="text-end mt-3">
            <label htmlFor="grandTotal" className="me-2" style={{ fontSize: '12px' }}>
              Grand Total:
            </label>
            <input
              type="text"
              className="form-control d-inline-block"
              id="grandTotal"
              value={grandTotal}
              placeholder="0.00"
              style={{ width: "120px" }}
              readOnly
            />
            <br />
            <br />
            <button className="btn btn-primary ms-2" onClick={handleSave}>
              Save
            </button>
            <button className="btn btn-primary ms-2" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewSalesOrder;
