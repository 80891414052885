import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../Services/api';

function LedgerGroupList() {
  const [ledgerGroups, setLedgerGroups] = useState([]);
  const [editingGroupId, setEditingGroupId] = useState(null); // Track which row is being edited
  const [editedGroup, setEditedGroup] = useState('');
  const [editedUnder, setEditedUnder] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.GetVibeLedgerGroup();
        setLedgerGroups(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (group) => {
    setEditingGroupId(group.id);
    setEditedGroup(group.group);
    setEditedUnder(group.under);
  };

  const handleSaveClick = async (groupId) => {
    const updatedGroupData = {
      id: groupId,
      group: editedGroup,
      under: editedUnder,
    };

    try {
      // Call the API to update the group data
      await api.PostUpdateVibeGroup(updatedGroupData);

      // Update the group locally after successful API call
      setLedgerGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.id === groupId ? { ...group, group: editedGroup, under: editedUnder } : group
        )
      );
      setEditingGroupId(null); // Exit edit mode
    } catch (error) {
      console.error('Failed to save the changes:', error.message);
    }
  };

  const handleCancelClick = () => {
    setEditingGroupId(null); // Exit edit mode without saving
  };

  const handleAddButtonClick = () => {
    navigate('/GroupAdd');
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Ledger Group List</h3>
        <button className="btn btn-primary" onClick={handleAddButtonClick}>
          Add Ledger Group
        </button>
      </div>
      <div className="card">
        <div className="card-body">
          <table className="table table-bordered mt-3">
            <thead>
              <tr>
                <th>Company</th>
                <th>Group</th>
                <th>Under</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {ledgerGroups.length > 0 ? (
                ledgerGroups.map((group) => (
                  <tr key={group.id}>
                    <td>{group.company}</td>
                    <td>
                      {editingGroupId === group.id ? (
                        <input
                          type="text"
                          className="form-control"
                          value={editedGroup}
                          onChange={(e) => setEditedGroup(e.target.value)}
                        />
                      ) : (
                        group.group
                      )}
                    </td>
                    <td>
                      {editingGroupId === group.id ? (
                        <input
                          type="text"
                          className="form-control"
                          value={editedUnder}
                          onChange={(e) => setEditedUnder(e.target.value)}
                        />
                      ) : (
                        group.under
                      )}
                    </td>
                    <td>
                      {editingGroupId === group.id ? (
                        <>
                          <button
                            className="btn btn-success me-2"
                            onClick={() => handleSaveClick(group.id)}
                          >
                            Save
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={handleCancelClick}
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleEditClick(group)}
                        >
                          <i class="bi bi-pen"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default LedgerGroupList;
