import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from "../Services/api";
import { useNavigate } from "react-router-dom";

function Ledger() {
  const [companies, setCompanies] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [ledgerName, setLedgerName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [amount, setAmount] = useState(0);
  const [drCr, setDrCr] = useState('');
  const [billNo, setBillNo] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companyData = await api.GetVibeCompany();
        setCompanies(companyData);
      } catch (error) {
        console.error('Failed to fetch companies:', error);
      }
    };

    const fetchGroups = async () => {
      try {
        const groupData = await api.GetVibeGroupdropdown();
        setGroups(groupData.map(group => ({
          label: group.group,
          id: group.id,
        })));
      } catch (error) {
        console.error('Failed to fetch groups:', error);
      }
    };

    fetchCompanies();
    fetchGroups();
  }, []);

  const handleSave = async () => {
    if (!selectedCompany || !selectedGroup || !ledgerName) {
      alert('Please fill out all required fields.');
      return;
    }

    const ledgerData = {
      ledger: ledgerName,
      company: selectedCompany.value,
      group: selectedGroup.id,
      amount: parseFloat(amount),
      drcr: drCr,
      bill: billNo
    };

    try {
      const response = await api.PostLedger(ledgerData);
      alert('Ledger saved successfully!');
      navigate('/Ledgerlist');
    } catch (error) {
      alert(`Failed to save ledger: ${error.message}`);
    }
  };

  const handleCancel = () => {
    navigate('/Ledgerlist');
  };

  return (
    <div className="container mt-4">
      <h2>Ledger Creation</h2>
      <div className="card shadow mt-3">
        <div className="card-body">
          <div className="mb-3 row">
            <label htmlFor="company" className="col-md-2 col-form-label">
              Company
            </label>
            <div className="col-md-4">
              <Typeahead
                id="company"
                options={companies.map(company => ({ label: company.company, value: company.id }))}
                placeholder="Choose a company..."
                labelKey="label"
                onChange={(selected) => setSelectedCompany(selected[0])}
                selected={selectedCompany ? [selectedCompany] : []}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="ledgerName" className="col-md-2 col-form-label">
              Ledger Name
            </label>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                id="ledgerName"
                value={ledgerName}
                onChange={(e) => setLedgerName(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="groupName" className="col-md-2 col-form-label">
              Under
            </label>
            <div className="col-md-4">
              <Typeahead
                id="groupName"
                options={groups}
                placeholder="Choose a group..."
                labelKey="label"
                onChange={(selected) => setSelectedGroup(selected[0])}
                selected={selectedGroup ? [selectedGroup] : []}
              />
            </div>
          </div>
          <h5 className="mt-4">Opening Balance</h5>
          <div className="mb-3 row">
            <label htmlFor="amount" className="col-md-2 col-form-label">
              Amount
            </label>
            <div className="col-md-4">
              <input
                type="number"
                className="form-control"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="drCr" className="col-md-2 col-form-label">
              Dr./Cr.
            </label>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                id="drCr"
                value={drCr}
                onChange={(e) => setDrCr(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="billNo" className="col-md-2 col-form-label">
              Bill No.
            </label>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                id="billNo"
                value={billNo}
                onChange={(e) => setBillNo(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-start mt-4">
            <button className="btn btn-primary me-2" onClick={handleSave}>
              Save
            </button>
            <button className="btn btn-secondary" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ledger;
