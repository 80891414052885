import React, { useState, useEffect, useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { Dropdown, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure you import bootstrap CSS

const containerStyle = {
  width: "100%",
  height: "400px",
};

const places = [
  { id: { lat: 25.6905, lng: 85.209 }, name: "Patna" },
  { id: { lat: 12.9716, lng: 77.594 }, name: "Bangalore" },
  { id: { lat: 28.7041, lng: 77.102 }, name: "Delhi" },
];

const Mags = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCJX3x7z_VTAy3083qSokbhy_gHfmwxdJw",
    libraries: ["places"],
  });

  const [map, setMap] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [directions, setDirections] = useState(null);
  const [targetLocation, setTargetLocation] = useState(places[0].id); // Set default target location
  const [selectedOption, setSelectedOption] = useState(places[0].name); // Set default selected option

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const location = { lat: latitude, lng: longitude };
          setCurrentLocation(location);
          if (isLoaded && window.google && window.google.maps) {
            calculateRoute(location, targetLocation);
          }
        },
        (error) => {
          console.error("Error getting current position:", error);
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, [isLoaded, targetLocation]);

  const calculateRoute = (origin, destination) => {
    const service = new window.google.maps.DirectionsService();
    service.route(
      {
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === "OK") {
          setDirections(result);
        } else {
          console.error("Error fetching directions:", status);
        }
      }
    );
  };

  const handleSelect = (eventKey) => {
    const selectedPlace = places.find((place) => place.name === eventKey);
    setSelectedOption(eventKey);
    setTargetLocation(selectedPlace.id);
  };

  return isLoaded ? (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <Card>
            <Card.Body>
              <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {selectedOption}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {places.map((place) => (
                    <Dropdown.Item key={place.name} eventKey={place.name}>
                      {place.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <br/>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={currentLocation || targetLocation}
                zoom={20}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                {currentLocation && <Marker position={currentLocation} />}
                <Marker position={targetLocation} />
                {directions && <DirectionsRenderer directions={directions} />}
              </GoogleMap>
              <div>
                <p>
                  Current Location:{" "}
                  {currentLocation
                    ? `${currentLocation.lat}, ${currentLocation.lng}`
                    : "Loading..."}
                </p>
                <p>
                  Distance to Target:{" "}
                  {directions
                    ? `${(
                        directions.routes[0].legs[0].distance.value / 1000
                      ).toFixed(2)} km`
                    : "Calculating..."}
                </p>
                <p>
                  Duration:{" "}
                  {directions
                    ? `${directions.routes[0].legs[0].duration.text}`
                    : "Calculating..."}
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default React.memo(Mags);

