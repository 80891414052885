import React, { useState } from "react";
import { Card, Container, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../Services/api";

const Customerformdata = () => {
  const [formData, setFormData] = useState({
    distributorshipname: "",
    proprietorname: "",
    distributorshopaddress: "",
    customermobilenumber: "",
    presentsalecompetitioninr: "",
    presentsalecompetitionctnmonth: "",
    numberofsalespoints: "",
    expectedsale: "",
    dateofcommencement: "",
    olddistributorworking: false,
    fullfinalsubmitted: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.PostVibeCustomer({ groups: [formData] });
      alert("Data submitted successfully!");
    } catch (error) {
      console.error("Error submitting data:", error);
      alert(`Error submitting data: ${error.message}`);
    }
  };

  return (
    <div style={{
      minHeight: '100vh',
      background: 'linear-gradient(45deg, rgba(173, 216, 230, 0.8), rgba(0, 0, 0, 0.8))',
      boxShadow: '0 0 20px rgba(173, 216, 230, 0.6)',
      padding: '20px'
    }}>
      <Container className="mt-4">
        <header className="mb-4">
          <h1>Customer Creation</h1>
        </header>

        <Form onSubmit={handleSubmit}>
          {/* Distributorship Information */}
          <Card className="mb-4">
            <Card.Body>
              <h5>Distributorship Information</h5>
              <Form.Group className="mb-3 col-md-4">
                <Form.Label>Name of Distributorship</Form.Label>
                <Form.Control
                  type="text"
                  name="distributorshipname"
                  value={formData.distributorshipname}
                  onChange={handleChange}
                  placeholder="Enter name of distributorship"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4">
                <Form.Label>Name of Proprietor/Partner</Form.Label>
                <Form.Control
                  type="text"
                  name="proprietorname"
                  value={formData.proprietorname}
                  onChange={handleChange}
                  placeholder="Enter name of proprietor/partner"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4">
                <Form.Label>Address of Distributor’s Shop</Form.Label>
                <Form.Control
                  type="text"
                  name="distributorshopaddress"
                  value={formData.distributorshopaddress}
                  onChange={handleChange}
                  placeholder="Enter address of distributor’s shop"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4">
                <Form.Label>Customer Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  name="customermobilenumber"
                  value={formData.customermobilenumber}
                  onChange={handleChange}
                  placeholder="Enter customer mobile number"
                />
              </Form.Group>
            </Card.Body>
          </Card>

          {/* Sales Information */}
          <Card className="mb-4">
            <Card.Body>
              <h5>Sales Information</h5>
              <Form.Group className="mb-3 col-md-4">
                <Form.Label>Present Sale of Competition (INR)</Form.Label>
                <Form.Control
                  type="text"
                  name="presentsalecompetitioninr"
                  value={formData.presentsalecompetitioninr}
                  onChange={handleChange}
                  placeholder="Enter present sale of competition (INR)"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4">
                <Form.Label>Present Sale of Competition (Ctn/month)</Form.Label>
                <Form.Control
                  type="text"
                  name="presentsalecompetitionctnmonth"
                  value={formData.presentsalecompetitionctnmonth}
                  onChange={handleChange}
                  placeholder="Enter present sale of competition (Ctn/month)"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4">
                <Form.Label>No. of Sales Points / Retailers Currently Serviced</Form.Label>
                <Form.Control
                  type="text"
                  name="numberofsalespoints"
                  value={formData.numberofsalespoints}
                  onChange={handleChange}
                  placeholder="Enter number of sales points/retailers currently serviced"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4">
                <Form.Label>Expected Sale</Form.Label>
                <Form.Control
                  type="text"
                  name="expectedsale"
                  value={formData.expectedsale}
                  onChange={handleChange}
                  placeholder="Enter expected sale"
                />
              </Form.Group>
            </Card.Body>
          </Card>

          {/* Business Commencement */}
          <Card className="mb-4">
            <Card.Body>
              <h5>Business Commencement</h5>
              <Form.Group className="mb-3 col-md-4">
                <Form.Label>Date of Commencement of Business</Form.Label>
                <Form.Control
                  type="date"
                  name="dateofcommencement"
                  value={formData.dateofcommencement}
                  onChange={handleChange}
                />
              </Form.Group>
            </Card.Body>
          </Card>

          {/* Distributor Transition Information */}
          <Card className="mb-4">
            <Card.Body>
              <h5>Distributor Transition Information</h5>
              <Form.Group className="mb-3 col-md-4">
                <Form.Label>Old Distributor Still Working</Form.Label>
                <Form.Check
                  type="switch"
                  name="olddistributorworking"
                  checked={formData.olddistributorworking}
                  onChange={handleChange}
                  label={formData.olddistributorworking ? "Yes" : "No"}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Full & Final of Old Distributor Submitted</Form.Label>
                <Form.Check
                  type="switch"
                  name="fullfinalsubmitted"
                  checked={formData.fullfinalsubmitted}
                  onChange={handleChange}
                  label={formData.fullfinalsubmitted ? "Yes" : "No"}
                />
              </Form.Group>
              <Button variant="primary" type="submit">Submit</Button>
            </Card.Body>
          </Card>
        </Form>
      </Container>
    </div>
  );
};

export default Customerformdata;
