import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

function ExpenseForm() {
  const [voucherDate, setVoucherDate] = useState("");
  const [voucherNumber, setVoucherNumber] = useState("");
  const [typeOfExpense, setTypeOfExpense] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [user, setUser] = useState("");  // Added user state
  const [status, setStatus] = useState(""); // State for status
  const [reason, setReason] = useState(""); // State for reason

  const handleSave = () => {
    const payload = {
      voucherDate,
      voucherNumber,
      typeOfExpense,
      expenseAmount,
      remarks,
      user,
      status, // Include status in payload
      reason, // Include reason in payload
    };

    // Log payload for demonstration purposes
    console.log("Payload to be saved:", payload);

    // Clear form after saving
    setVoucherDate("");
    setVoucherNumber("");
    setTypeOfExpense("");
    setExpenseAmount("");
    setRemarks("");
    setUser("");
    setStatus(""); // Clear status field
    setReason(""); // Clear reason field
  };

  return (
    <div className="container mt-4">
      <h2>Expense Approval</h2>
      <div className="card shadow mt-3">
        <div className="card-body">
          <div className="row mb-3">
            <label htmlFor="user" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              User
            </label>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                id="user"
                value={user}
                readOnly
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="voucherDate" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Voucher Date
            </label>
            <div className="col-md-4">
              <input
                type="date"
                className="form-control"
                id="voucherDate"
                value={voucherDate}
                readOnly
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="voucherNumber" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Voucher Number
            </label>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                id="voucherNumber"
                value={voucherNumber}
                readOnly
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="typeOfExpense" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Type of Expense
            </label>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                id="typeOfExpense"
                value={typeOfExpense}
                readOnly
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="expenseAmount" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Expense Amount
            </label>
            <div className="col-md-4">
              <input
                type="number"
                className="form-control"
                id="expenseAmount"
                value={expenseAmount}
                readOnly
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="remarks" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Remarks
            </label>
            <div className="col-md-4">
              <textarea
                className="form-control"
                id="remarks"
                value={remarks}
                readOnly
              />
            </div>
          </div>

          {/* Status Typeahead */}
          <div className="row mb-3">
            <label htmlFor="status" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Status
            </label>
            <div className="col-md-4">
              <Typeahead
                id="status"
                onChange={(selected) => setStatus(selected[0] || "")}
                options={["Approved", "Rejected"]}
                placeholder="Select Status"
                selected={status ? [status] : []}
              />
            </div>
          </div>

          {/* Reason Textarea */}
          <div className="row mb-3">
            <label htmlFor="reason" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Reason
            </label>
            <div className="col-md-4">
              <textarea
                className="form-control"
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                rows={3}
              />
            </div>
          </div>

          <button className="btn btn-primary" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExpenseForm;
