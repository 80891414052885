import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../Services/api';

const Ledgerlist = () => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingExpenseId, setEditingExpenseId] = useState(null);
  const [editedLedgers, setEditedLedgers] = useState('');
  const [editedGroup, setEditedGroup] = useState([]);
  const [editedDrCr, setEditedDrCr] = useState('');
  const [editedBillNo, setEditedBillNo] = useState('');
  const [editedAmount, setEditedAmount] = useState('');
  const [groupOptions, setGroupOptions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ledgerData, groupData] = await Promise.all([
          api.GetLedgerlist(),
          api.GetVibeGroupdropdown()
        ]);

        setExpenses(ledgerData);
        setGroupOptions(groupData.map(group => group.group));
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (expense) => {
    setEditingExpenseId(expense.id);
    setEditedLedgers(expense.ledgers);
    setEditedGroup([expense.group]);
    setEditedDrCr(expense.drcr);
    setEditedBillNo(expense.billno);
    setEditedAmount(expense.amount);
  };

  const handleSaveClick = async (expenseId) => {
    const updatedExpenseData = {
      id: expenseId,
      ledgers: editedLedgers,
      group: editedGroup[0],
      amount: parseFloat(editedAmount),
      drcr: editedDrCr,
      bill: editedBillNo,
    };

    try {
      await api.PostUpdateLedger(updatedExpenseData);

      setExpenses((prevExpenses) =>
        prevExpenses.map((expense) =>
          expense.id === expenseId
            ? {
                ...expense,
                ledgers: editedLedgers,
                group: editedGroup[0],
                drcr: editedDrCr,
                billno: editedBillNo,
                amount: parseFloat(editedAmount),
              }
            : expense
        )
      );
      setEditingExpenseId(null);
    } catch (error) {
      console.error('Failed to save the changes:', error.message);
    }
  };

  const handleCancelClick = () => {
    setEditingExpenseId(null);
  };

  const handleAddButtonClick = () => {
    navigate('/Ledger');
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Ledger List</h2>
        <button className="btn btn-primary" onClick={handleAddButtonClick}>
          Add
        </button>
      </div>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Ledgers</th>
            <th scope="col">Company</th>
            <th scope="col">Group</th>
            <th scope="col">DR/CR</th>
            <th scope="col">Bill No</th>
            <th scope="col">Amount</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="7">Loading...</td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan="7">Error: {error}</td>
            </tr>
          ) : expenses.length > 0 ? (
            expenses.map((expense) => (
              <tr key={expense.id}>
                <td>
                  {editingExpenseId === expense.id ? (
                    <input
                      type="text"
                      className="form-control"
                      value={editedLedgers}
                      onChange={(e) => setEditedLedgers(e.target.value)}
                    />
                  ) : (
                    expense.ledgers
                  )}
                </td>
                <td>{expense.company}</td>
                <td>
                  {editingExpenseId === expense.id ? (
                    <Typeahead
                      id="group"
                      options={groupOptions}
                      placeholder="Select group..."
                      className="form-control"
                      onChange={setEditedGroup}
                      selected={editedGroup}
                    />
                  ) : (
                    expense.group
                  )}
                </td>
                <td>
                  {editingExpenseId === expense.id ? (
                    <input
                      type="text"
                      className="form-control"
                      value={editedDrCr}
                      onChange={(e) => setEditedDrCr(e.target.value)}
                    />
                  ) : (
                    expense.drcr
                  )}
                </td>
                <td>
                  {editingExpenseId === expense.id ? (
                    <input
                      type="text"
                      className="form-control"
                      value={editedBillNo}
                      onChange={(e) => setEditedBillNo(e.target.value)}
                    />
                  ) : (
                    expense.billno
                  )}
                </td>
                <td>
                  {editingExpenseId === expense.id ? (
                    <input
                      type="number"
                      step="0.01"
                      className="form-control"
                      value={editedAmount}
                      onChange={(e) => setEditedAmount(e.target.value)}
                    />
                  ) : (
                    expense.amount
                  )}
                </td>
                <td>
                  {editingExpenseId === expense.id ? (
                    <>
                      <button
                        className="btn btn-success me-2"
                        onClick={() => handleSaveClick(expense.id)}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => handleEditClick(expense)}
                    >
                      <i class="bi bi-pen"></i>
                    </button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No Ledger found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Ledgerlist;
