import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useNavigate } from "react-router-dom";
import api from "../Services/api";


function Purchaseinvoice() {
  const [soDate, setSoDate] = useState("");
  const [piDate, setPIDate] = useState("");
  const [piNO, setPiNO] = useState("");
  const [siDate, setSIDate] = useState("");
  const [siNo, setSiNO] = useState("");
  const [poDate, setPoDate] = useState("");
  const [poNo, setPoNO] = useState("");
  const [customer, setCustomer] = useState("");
  const [soNumber, setSoNumber] = useState(""); 
  const [inventory, setInventory] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [taxableAmount, setTaxableAmount] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [igst, setIgst] = useState(0);
  const [roundoff, setRoundoff] = useState(0);
  const [deliveryDetails, setDeliveryDetails] = useState([]); 
  const [selectedItemDetails, setSelectedItemDetails] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [customers, setCustomers] = useState([]); 


  const navigate = useNavigate();


  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companyData = await api.GetVibeCompany();
        setCompanies(companyData);
      } catch (error) {
        console.error("Failed to fetch companies", error);
      }
    };

    const fetchCustomers = async () => {
      try {
        const customerData = await api.GetVibeCustomer(); 
        setCustomers(customerData);
      } catch (error) {
        console.error("Failed to fetch customers", error);
      }
    };

    fetchCompanies();
    fetchCustomers();
  }, []);

  useEffect(() => {
    const fetchDeliveryDetails = async () => {
      if (customer) {
        try {
          const deliveryData = await api.GetVibeDeliveryDropdown(customer);
          setDeliveryDetails(deliveryData);
        } catch (error) {
          console.error("Failed to fetch delivery details", error);
        }
      }
    };

    fetchDeliveryDetails();
  }, [customer]);

  const handleSave = async () => {
    const payload = {
      pidate:piDate,
      pino:piNO,
      sidate:siDate,
      sino:siNo,
      podate:poDate,
      pono:poNo,
      sodate: soDate,
      customer,
      grandtotal: parseFloat(grandTotal),
      taxableAmount: parseFloat(taxableAmount),
      cgst: parseFloat(cgst),
      sgst: parseFloat(sgst),
      igst: parseFloat(igst),
      roundoff: parseFloat(roundoff),
      inventory: inventory.map(item => ({
        item: item.deliverno, 
        soqty: parseFloat(item.soqty),
        itemPrice: parseFloat(item.itemPrice),
        cPrice: parseFloat(item.cPrice),
        total: parseFloat(item.total)
      }))
    };

    try {
      const response = await api.PostVibePurchaseInvoice(payload);
      console.log("Sales Invoice saved successfully:", response);

      // Redirect to SalesOrderlist page
      navigate("/SalesOrderlist");
    } catch (error) {
      console.error("Failed to save Sales Invoice", error);
      alert(`Failed to save Sales Invoice: ${error.message}`);
    }
  };

  const handleInventoryChange = (index, key, value) => {
    const updatedInventory = [...inventory];
    updatedInventory[index][key] = value;
    if (key === "soqty" || key === "cPrice") {
      updatedInventory[index].total =
        ((Number(updatedInventory[index].soqty) || 0) *
        (Number(updatedInventory[index].cPrice) || 0)).toFixed(2);
    }
    setInventory(updatedInventory);
    updateGrandTotal(updatedInventory);
  };

  const updateGrandTotal = (updatedInventory) => {
    // Calculate the taxable amount based on the sum of all item totals
    const newTaxableAmount = updatedInventory
      .reduce((total, item) => total + (Number(item.total) || 0), 0)
      .toFixed(2);
  
    // Set the taxable amount
    setTaxableAmount(newTaxableAmount);
  
    // Example CGST, SGST, and IGST calculation based on the taxable amount
    const calculatedCgst = (newTaxableAmount * 0.09).toFixed(2); // 9% CGST
    const calculatedSgst = (newTaxableAmount * 0.09).toFixed(2); // 9% SGST
    const calculatedIgst = (newTaxableAmount * 0.18).toFixed(2); // 18% IGST
  
    // Set CGST, SGST, or IGST based on conditions
    setCgst(calculatedCgst);
    setSgst(calculatedSgst);
    setIgst(calculatedIgst);
  
    // Calculate the total tax based on applicable taxes
    const totalTax = Number(calculatedCgst) + Number(calculatedSgst) + Number(calculatedIgst);
  
    // Calculate the grand total as the sum of taxable amount and total tax
    let calculatedGrandTotal = (Number(newTaxableAmount) + totalTax).toFixed(2);
  
    // Calculate the round-off value
    const roundOffValue = (Math.round(calculatedGrandTotal) - calculatedGrandTotal).toFixed(2);
    setRoundoff(roundOffValue);
  
    // Update the grand total with round-off adjustment
    calculatedGrandTotal = (Number(calculatedGrandTotal) + Number(roundOffValue)).toFixed(2);
  
    // Set the grand total
    setGrandTotal(calculatedGrandTotal);
  };

  const handleItemDetailChange = async (itemId) => {
    if (itemId && !selectedItemDetails.includes(itemId)) {
      setSelectedItemDetails([...selectedItemDetails, itemId]);
      
      try {
        const deliveryData = await api.GetVibeDeliverylist(itemId);
        
        const formattedData = deliveryData.map((item) => ({
          itemCode: item.item,
          deliverno: item.item,
          soqty: item.qty,
          itemPrice: item.price,
          cPrice: item.price,
          total: item.total,
        }));

        setInventory(formattedData);
        updateGrandTotal(formattedData);
      } catch (error) {
        console.error("Failed to fetch delivery details by ID", error);
      }
    }
  };

  return (
    <div className="container mt-4">
      <h2>Purchase Invoice</h2>
      <div className="card shadow mt-3">
        <div className="card-body">
        <div className="row mb-3">
        <label htmlFor="piDate" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Purchase Invoice Date
            </label>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                id="piDate"
                value={piDate}
                onChange={(e) => setPIDate(e.target.value)}
              />
            </div>
          
            <label htmlFor="piNO" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Purchase Invoice No
            </label>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                id="piNO"
                value={piNO}
                onChange={(e) => setPiNO(e.target.value)}
                readonly
              />
            </div>
          </div>
          <div className="row mb-3">
        <label htmlFor="siDate" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Supplier Invoice Date
            </label>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                id="siDate"
                value={siDate}
                onChange={(e) => setSIDate(e.target.value)}
              />
            </div>
          
            <label htmlFor="siNo" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Supplier Invoice No
            </label>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                id="siNo"
                value={siNo}
                onChange={(e) => setSiNO(e.target.value)}
                readonly
              />
            </div>
          </div>
          <div className="row mb-3">
        <label htmlFor="poDate" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Purchase order Date
            </label>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                id="poDate"
                value={poDate}
                onChange={(e) => setPoDate(e.target.value)}
              />
            </div>
          
            <label htmlFor="poNo" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Purchase order No
            </label>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                id="poNo"
                value={poNo}
                onChange={(e) => setPoNO(e.target.value)}
                readonly
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="soDate" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Sales Invoice Date
            </label>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                id="deliveryDate"
                value={soDate}
                onChange={(e) => setSoDate(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="company" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Company
            </label>
            <div className="col-md-3">
              <Typeahead
                id="company"
                onChange={(selected) => {
                  setCompanies(selected[0]?.id || "");
                }}
                options={companies} // Use fetched data
                labelKey="company" // Ensure this matches the actual data structure
                placeholder="Select Company"
              />
            </div>
          
            <label htmlFor="customer" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Customer
            </label>
            <div className="col-md-3">
              <Typeahead
                id="customer"
                onChange={(selected) => {
                  setCustomer(selected[0]?.id || "");
                }}
                options={customers}
                labelKey="name" // Ensure this matches the actual data structure
                placeholder="Select Customer"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="deliveryDetails" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Delivery No
            </label>
            <div className="col-md-3">
              <Typeahead
                id="deliveryDetails"
                onChange={(selected) => {
                  handleItemDetailChange(selected[0]?.id || "");
                }}
                options={deliveryDetails}
                labelKey="do"
                placeholder="Select Delivery No"
              />
            </div>
            <label htmlFor="soDate" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Delivery Date
            </label>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                id="deliveryDate"
                value={soDate}
                onChange={(e) => setSoDate(e.target.value)}
              />
            </div>
          </div>
          {/* Table for Sales Order Details */}
          <table className="table mt-4">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Item Name</th>
                <th>SO Qty</th>
                <th>Price List</th>
                <th>SO Price</th>
                <th>Total Amt</th>
              </tr>
            </thead>
            <tbody>
              {inventory.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td> {/* Serial Number */}
                  <td>{item.deliverno}</td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={item.soqty}
                      onChange={(e) =>
                        handleInventoryChange(
                          index,
                          "soqty",
                          parseFloat(e.target.value)
                        )
                      }
                      step="0.01"  // Allow decimal values
                      min="0"     // Prevent negative values
                      style={{ width: '100px' }} // Adjust width as needed
                    />
                  </td>
                  <td>{item.itemPrice}</td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={item.cPrice || 0}
                      onChange={(e) =>
                        handleInventoryChange(
                          index,
                          "cPrice",
                          parseFloat(e.target.value) || 0
                        )
                      }
                      step="0.01"  // Allow decimal values
                      min="0"     // Prevent negative values
                      style={{ width: '100px' }} // Adjust width as needed
                    />
                  </td>
                  <td>{(Number(item.total) || 0)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Tax and Total Fields */}
          <div className="mt-4">
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label" style={{ fontSize: '12px' }}>
                  Taxable Amount:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block"
                  id="taxableAmount"
                  value={taxableAmount}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label" style={{ fontSize: '12px' }}>
                  CGST:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block"
                  id="cgst"
                  value={cgst}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label" style={{ fontSize: '12px' }}>
                  SGST:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block"
                  id="sgst"
                  value={sgst}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label" style={{ fontSize: '12px' }}>
                  IGST:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block"
                  id="igst"
                  value={igst}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label" style={{ fontSize: '12px' }}>
                  Round Off:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block"
                  id="roundoff"
                  value={roundoff}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label fw-bold" style={{ fontSize: '14px' }}>
                  Grand Total:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block fw-bold"
                  id="grandTotal"
                  value={grandTotal}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <button
              className="btn btn-primary me-2"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/SalesOrderlist")}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Purchaseinvoice;
