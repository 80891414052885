import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../Services/api";

const Stocklist = () => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.GetStockItemlist();
        setExpenses(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddButtonClick = () => {
    navigate("/Stockitem");
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Stock Item List</h2>
        <button className="btn btn-primary" onClick={handleAddButtonClick}>
          Add
        </button>
      </div>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Items</th>
            <th scope="col">Company</th>
            {/* <th scope="col">Under</th> */}
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan="5">Error: {error}</td>
            </tr>
          ) : expenses.length > 0 ? (
            expenses.map((expense) => (
              <tr key={expense.id}>
                <td>{expense.items}</td>
                <td>{expense.company}</td>
                {/* <td>{expense.under}</td> */}
                <td>{expense.amount}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No Stock found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Stocklist;
