import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from '../Services/api';

const Manufacturing = () => {
  const [components, setComponents] = useState([]);
  const [scrap, setScrap] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [godownOptions, setGodownOptions] = useState([]);
  const [stockItemOptions, setStockItemOptions] = useState([]);
  const [bomOptions, setBomOptions] = useState({});
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedGodown, setSelectedGodown] = useState(null);
  const [selectedStockItem, setSelectedStockItem] = useState(null);
  const [bomName, setBomName] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [originalQuantities, setOriginalQuantities] = useState({});
  const [scrapOptions, setScrapOptions] = useState([]);

  // Fetch company data from API
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companyData = await api.GetVibeCompany();
        setCompanyOptions(companyData);
      } catch (error) {
        console.error("Failed to fetch companies", error);
      }
    };

    fetchCompanies();
  }, []);

  // Fetch godown data from API
  useEffect(() => {
    const fetchGodowns = async () => {
      try {
        const godownData = await api.GetVibeGodown();
        setGodownOptions(godownData);
      } catch (error) {
        console.error("Failed to fetch godowns", error);
      }
    };

    fetchGodowns();
  }, []);

  // Fetch stock items and BOM data
  useEffect(() => {
    const fetchStockBOM = async () => {
      try {
        const stockBOMData = await api.StockBOM();
        setStockItemOptions(stockBOMData.map(item => item.item));
        setBomOptions(stockBOMData.reduce((acc, item) => {
          acc[item.item] = item.bom;
          return acc;
        }, {}));
      } catch (error) {
        console.error("Failed to fetch stock BOM data", error);
      }
    };

    fetchStockBOM();
  }, []);

  useEffect(() => {
    const fetchScrapStock = async () => {
      try {
        const scrapStockData = await api.ScrapStockBOM();
        setScrapOptions(scrapStockData);
      } catch (error) {
        console.error("Failed to fetch scrap stock items", error);
      }
    };

    fetchScrapStock();
  }, []);

  // Fetch stock item details by ID and update components table
  const fetchStockItemById = async (id) => {
    try {
      const data = await api.GetStocklineitembyid(id);
      const updatedComponents = data.map(item => ({
        item: item.item,
        godown: item.godown,
        qty: item.qty,
        rate: 0,
        amount: 0
      }));

      setOriginalQuantities(updatedComponents.reduce((acc, component) => {
        acc[component.item] = component.qty;
        return acc;
      }, {}));

      setComponents(updatedComponents);
    } catch (error) {
      console.error('Failed to fetch stock item by ID', error);
    }
  };

  const handleAddRow = (table) => {
    if (table === 'components') {
      setComponents([...components, { item: '', godown: '', qty: 0, rate: 0, amount: 0 }]);
    } else if (table === 'scrap') {
      setScrap([...scrap, { item: '', godown: '', qty: 0, rate: 0, amount: 0 }]);
    }
  };

  const handleRemoveRow = (index, table) => {
    if (table === 'components') {
      setComponents(components.filter((_, i) => i !== index));
    } else if (table === 'scrap') {
      setScrap(scrap.filter((_, i) => i !== index));
    }
  };

  const handleSave = async () => {
    const manufacturingData = {
      voucherNo: "Auto-generated",  // You may want to generate or fetch this dynamically
      voucherDate: document.getElementById('voucherDate').value,
      batch: document.getElementById('batch').value,
      company: selectedCompany,
      stockItemName: selectedStockItem,
      bomName,
      godown: selectedGodown,
      quantity,
      manufacturingDate: document.getElementById('manufacturingDate').value,
      expiryDate: document.getElementById('expiryDate').value,
      components,
      scrap
    };

    try {
      await api.PostVibeManufacturing(manufacturingData);
      console.log('Manufacturing data saved successfully');
    } catch (error) {
      console.error('Failed to save manufacturing data', error);
    }
  };

  const handleCancel = () => {
    console.log('Canceled');
  };

  const updateComponentField = (index, field, value) => {
    const updatedComponents = components.map((component, i) => {
      if (i === index) {
        const updatedComponent = {
          ...component,
          [field]: value,
        };

        if (field === 'qty' || field === 'rate') {
          const qty = field === 'qty' ? value : component.qty;
          const rate = field === 'rate' ? value : component.rate;
          updatedComponent.amount = qty * rate;
        }

        return updatedComponent;
      }
      return component;
    });

    setComponents(updatedComponents);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseFloat(e.target.value);
    setQuantity(newQuantity);

    const updatedComponents = components.map((component) => ({
      ...component,
      qty: originalQuantities[component.item] * newQuantity,
      amount: (originalQuantities[component.item] * newQuantity) * component.rate,
    }));

    setComponents(updatedComponents);
  };

  const updateScrapField = (index, field, value) => {
    const updatedScraps = scrap.map((scrap, i) => {
      if (i === index) {
        const updatedScraps = {
          ...scrap,
          [field]: value,
        };

        if (field === 'qty' || field === 'rate') {
          const qty = field === 'qty' ? value : scrap.qty;
          const rate = field === 'rate' ? value : scrap.rate;
          updatedScraps.amount = qty * rate;
        }

        return updatedScraps;
      }
      return scrap;
    });

    setScrap(updatedScraps);
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <h2 className="card-title"><b>Manufacturing</b></h2>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form>
                <div className="row mb-3">
                  <label htmlFor="voucherNo" className="col-md-1 col-form-label">Voucher No.</label>
                  <div className="col-md-2">
                    <input type="text" className="form-control" id="voucherNo" value="Auto-generated" readOnly />
                  </div>
                  <label htmlFor="voucherDate" className="col-md-1 col-form-label">Voucher Date</label>
                  <div className="col-md-2">
                    <input type="date" className="form-control" id="voucherDate" />
                  </div>
                  <label htmlFor="batch" className="col-md-1 col-form-label">Batch</label>
                  <div className="col-md-2">
                    <input type="text" className="form-control" id="batch" />
                  </div>
                  <label htmlFor="company" className="col-md-1 col-form-label" style={{ fontSize: '12px' }}>
                    Company
                  </label>
                  <div className="col-md-2">
                    <Typeahead
                      id="company"
                      onChange={(selected) => setSelectedCompany(selected[0] || null)}
                      options={companyOptions}
                      labelKey="company"
                      placeholder="Select Company"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="stockItemName" className="col-sm-2 col-form-label">Stock Item Name</label>
                  <div className="col-sm-3">
                    <Typeahead
                      id="stockItemName"
                      options={stockItemOptions}
                      placeholder="Search stock item..."
                      onChange={async (selected) => {
                        if (selected.length > 0) {
                          const selectedItem = selected[0];
                          setSelectedStockItem(selectedItem);
                          setBomName(bomOptions[selectedItem] || '');
                          await fetchStockItemById(selectedItem);
                        }
                      }}
                    />
                  </div>
                  <label htmlFor="bomName" className="col-sm-2 col-form-label">Name of BOM</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="bomName" value={bomName} readOnly />
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="godown" className="col-md-1 col-form-label" style={{ fontSize: '12px' }}>
                    Godown
                  </label>
                  <div className="col-md-2">
                    <Typeahead
                      id="godown"
                      onChange={(selected) => setSelectedGodown(selected[0] || null)}
                      options={godownOptions}
                      labelKey="godown"
                      placeholder="Select Godown"
                    />
                  </div>
                  <label htmlFor="quantity" className="col-md-1 col-form-label">Quantity</label>
                  <div className="col-md-2">
                    <input type="number" className="form-control" id="quantity" value={quantity} onChange={handleQuantityChange} />
                  </div>
                  <label htmlFor="manufacturingDate" className="col-md-1 col-form-label">Mfg Date</label>
                  <div className="col-md-2">
                    <input type="date" className="form-control" id="manufacturingDate" />
                  </div>
                  <label htmlFor="expiryDate" className="col-md-1 col-form-label">Exp Date</label>
                  <div className="col-md-2">
                    <input type="date" className="form-control" id="expiryDate" />
                  </div>
                </div>



                <div className="row mb-3">
                  <div className="col-md-12">
                    <h5>Components</h5>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Godown</th>
                          <th>Quantity</th>
                          <th>Rate</th>
                          <th>Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {components.map((component, index) => (
                          <tr key={index}>
                            <td>
                              <Typeahead
                                id={`item-${index}`}
                                options={stockItemOptions}
                                onChange={(selected) => updateComponentField(index, 'item', selected[0] || '')}
                                defaultSelected={[component.item]}
                              />
                            </td>
                            <td>
                              <Typeahead
                                id={`godown-${index}`}
                                options={godownOptions}
                                onChange={(selected) => updateComponentField(index, 'godown', selected[0] || '')}
                                defaultSelected={[component.godown]}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                value={component.qty}
                                onChange={(e) => updateComponentField(index, 'qty', parseFloat(e.target.value))}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                value={component.rate}
                                onChange={(e) => updateComponentField(index, 'rate', parseFloat(e.target.value))}
                              />
                            </td>
                            <td>{component.amount.toFixed(2)}</td>
                            <td>
                              <button type="button" className="btn btn-danger btn-sm" onClick={() => handleRemoveRow(index, 'components')}>Remove</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* <button type="button" className="btn btn-primary" onClick={() => handleAddRow('components')}>Add Component</button> */}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <h5>Scrap</h5>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Godown</th>
                          <th>Quantity</th>
                          <th>Rate</th>
                          <th>Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {scrap.map((scrapItem, index) => (
                          <tr key={index}>
                            <td>
                            <Typeahead
                      id={`scrap-item-${index}`}
                      options={scrapOptions.map(item => item.item)}
                      onChange={(selected) => updateScrapField(index, 'item', selected[0] || '')}
                      defaultSelected={[scrapItem.item]}
                    />
                            </td>
                            <td>
                            <Typeahead
                      id="godowns"
                      onChange={(selected) => updateScrapField(index, 'godown', selected[0] || '')}
                      options={godownOptions}
                      labelKey="godown"
                      defaultSelected={[scrapItem.godown]}

                    />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                value={scrapItem.qty}
                                onChange={(e) => updateScrapField(index, 'qty', parseFloat(e.target.value))}
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                value={scrapItem.rate}
                                onChange={(e) => updateScrapField(index, 'rate', parseFloat(e.target.value))}
                              />
                            </td>
                            <td>{scrapItem.amount.toFixed(2)}</td>
                            <td>
                              <button type="button" className="btn btn-danger btn-sm" onClick={() => handleRemoveRow(index, 'scrap')}>Remove</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <button type="button" className="btn btn-primary" onClick={() => handleAddRow('scrap')}>Add Scrap</button>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-11 justify-content">
                  <button type="button" className="btn btn-primary float-right" onClick={handleSave}>Save</button> 
                  <button type="button" className="btn btn-secondary float-right" onClick={handleCancel}>Cancel</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manufacturing;
