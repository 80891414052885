/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";

//  const BASE_URL = "http://localhost:5081/api"; // Adjust the base URL according to your backend API
const BASE_URL = "https://nsapi.pro/api"; // Adjust the base URL according to your backend API


const apiReference = axios.create({
  baseURL: BASE_URL,
});


apiReference.interceptors.request.use(
  (config) => {
    
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Define API functions
const api = {
  partyWarehouse: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Partymaster`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting records: ${error.message}`);
    }
  },
  
 



  Salesitem: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/SalesBill`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Sales Items: ${error.message}`);
    }
  },
  
  
  
  GetVibeCustomer: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/VibeCustomer`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Customer: ${error.message}`);
    }
  },
  GetVibeItem: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Item`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Item: ${error.message}`);
    }
  },
  GetVibeLedgerGroup: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/LedgerGroup`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Item: ${error.message}`);
    }
  },
  GetVibeGroupdropdown: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/LedgerGroup/Dropdown`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Item: ${error.message}`);
    }
  },
  GetVibeCompany: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Company`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Item: ${error.message}`);
    }
  },
  GetVibeReceiptlist: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Receipt`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Receipt: ${error.message}`);
    }
  },
  GetVibeGodown: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Company/Godown`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Item: ${error.message}`);
    }
  },
  StockBOM: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Manufacturing`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Item: ${error.message}`);
    }
  },
  ScrapStockBOM: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Manufacturing/Scrap`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Item: ${error.message}`);
    }
  },
  GetVibeLedgers: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Ledger/ledgers`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Item: ${error.message}`);
    }
  },
  GetVibeSalesBill: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/VibeSalesOrder`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Item: ${error.message}`);
    }
  },
  GetVibeDelivery: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/VibeDeliveryConroller`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Item: ${error.message}`);
    }
  },
  GetVibeDeliveryDropdown: async (id) => {
    try {
      const response = await apiReference.get(`/VibeDeliveryConroller/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Purchase: ${error.message}`);
    }
  },
  GetVibeDeliverylist: async (id) => {
    try {
      const response = await apiReference.get(`/VibeDeliveryConroller/list/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Purchase: ${error.message}`);
    }
  },
  GetVibeSalesBillDropdown: async (id) => {
    try {
      const response = await apiReference.get(`/VibeSalesOrder/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Purchase: ${error.message}`);
    }
  },
  GetVibeSalesBillItem: async (id) => {
    try {
      const response = await apiReference.get(`/VibeSalesOrder/list/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Purchase: ${error.message}`);
    }
  },
  GetVibeItemById: async (id) => {
    try {
      const response = await apiReference.get(`/Item/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Purchase: ${error.message}`);
    }
  },
  GetStocklineitembyid: async (id) => {
    try {
      const response = await apiReference.get(`/Manufacturing/list/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Purchase: ${error.message}`);
    }
  },

  GetWarehouseById: async (ID) => {
    try {
      const response = await apiReference.get(`/Godown/${ID}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Warehouse Details: ${error.message}`);
    }
  },
  PostVibeSalesInvoice: async (invoiceData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/VibeSalesInvoiceConroller/AddSalesInvoice`,
        invoiceData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostVibePurchaseInvoice: async (invoiceData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/PurchaseInvoiceConroller/AddPurchaseInvoice`,
        invoiceData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostVibeManufacturing: async (manufacturingData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/PostManufacturing/Manufacturing`,
        manufacturingData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostVibeCustomer: async (customerData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/VibeCustomer/MultipleGroup`,
        customerData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostVibeDelivery: async (deliverData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/VibeDeliveryConroller/AddDelivery`,
        deliverData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostVibeadditionalCustomer: async (additionalData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/VibeAdditionalCustomer/MultipleAdditional`,
        additionalData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostLedgerStockItem: async (customerData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/PostStock/Stockbom`,
        customerData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostVibesalesbill: async (salesData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/VibeSalesOrder/AddSales`,
        salesData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostVibeGroup: async (groupData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/LedgerGroup/AddLedgerGroup`,
        groupData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostUpdateVibeGroup: async (groupData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/LedgerGroup/UpdateGroup`,
        groupData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  // GetItem: async () => {
  //   try {
  //     const response = await apiReference.get(`${BASE_URL}/Item`, {});
  //     return response.data;
  //   } catch (error) {
  //     throw new Error(`Error getting Items: ${error.message}`);
  //   }
  // },
  GetItem: async (groupId) => {
    try {
      const response = await apiReference.get(
        `${BASE_URL}/StockItem/GetItems`,
        {
          params: {
            groupId: groupId,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Items: ${error.message}`);
    }
  },
  // GetFGM: async () => {
  //   try {
  //     const response = await apiReference.get(`${BASE_URL}/FG/FGItems`, {});
  //     return response.data;
  //   } catch (error) {
  //     throw new Error(`Error getting Warehouses: ${error.message}`);
  //   }
  // },
  GetItemById: async (ID) => {
    try {
      const response = await apiReference.get(`/Item/${ID}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Item Details: ${error.message}`);
    }
  },
  PostLedger: async (ledgerData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Ledger/AddLedger`,
        ledgerData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostUpdateLedger: async (ledgerData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Ledger/UpdateLedger`,
        ledgerData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  
  PostExpenses: async (expensesData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Expense/AddExpenses`,
        expensesData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostReceipt: async (expensesData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Receipt/AddReceipt`,
        expensesData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  GetTotalItem: async (filterParams) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Item/GetByItem`,
        filterParams
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  GetParty: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Partymaster`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Items: ${error.message}`);
    }
  },
  GetPartyById: async (ID) => {
    try {
      const response = await apiReference.get(`/Partymaster/${ID}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Customer Details: ${error.message}`);
    }
  },
  PostCustomerAdditional: async (additionalData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/VibeAdditionalCustomer/MultipleAdditional`,
        additionalData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },

  // Now you can use this 'api' instance to make authenticated requests
  login: async (username, password) => {
    try {
      const response = await apiReference.post(`${BASE_URL}/Login`, {
        username,
        password,
      });
      return response.data;
    } catch (error) {
      throw new Error(`Login failed: ${error.message}`);
    }
  },

  fetchRoles: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Role`, {});
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },

  GetLocation: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/User/Location`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Location: ${error.message}`);
    }
  },
  GetUsers: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/User`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting User: ${error.message}`);
    }
  },
  GetUserById: async (id) => {
    try {
      const response = await apiReference.get(`/User/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Stouckout Details: ${error.message}`);
    }
  },

  PostUser: async (userData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/User/AddUser`,
        userData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostExpense: async (expenseData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Payment/MultiplePayment`,
        expenseData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  updateRWM: async (id, price, from, valid) => {
    try {
      const response = await apiReference.put(`${BASE_URL}/Raw/${id}`, {
        id,
        price,
        from,
        valid,
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error updating user: ${error.message}`);
    }
  },
  updateUser: async (id, userName, email, mobileNumber) => {
    try {
      const response = await apiReference.put(`${BASE_URL}/User/${id}`, {
        id,
        userName,
        email,
        mobileNumber,
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error updating user: ${error.message}`);
    }
  },
  addSales: async (salesData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Registeredsales`,
        salesData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  addCreditnote: async (salesData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Creditnote/Creditnote`,
        salesData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },

  addRole: async (roleName) => {
    try {
      const response = await apiReference.post(`${BASE_URL}/Role`, {
        roleID: 0, // Assuming roleID is always 0 for a new role
        roleName: roleName,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },

  updateRole: async (roleId, roleName) => {
    try {
      const response = await apiReference.put(`${BASE_URL}/Role/${roleId}`, {
        roleName: roleName,
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error updating role: ${error.message}`);
    }
  },

  deleteRole: async (roleId) => {
    try {
      const response = await apiReference.delete(`/Role/${roleId}`);
      // return { success: true, message: 'Role deleted successfully' };
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  GetFilterSalesDetails: async (filterParams) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/SalesBill/GetBySalesBill`,
        filterParams
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  GetFilterCustomerDetails: async (filterParams) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Partymaster/GetByCustomerDetails`,
        filterParams
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  GetFilterItemDetails: async (filterParams) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/StockItem/GetByStockItem`,
        filterParams
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostPassword: async (passwordData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/User/PasswordUpdate`,
        passwordData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  GetCurrentUser: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/login/me`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Location: ${error.message}`);
    }
  },
  GetLedgerbalanceByLedger: async (ledgername) => {
    try {
      const response = await apiReference.get(`/CreditLedger/${ledgername}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Balance: ${error.message}`);
    }
  },
  PostCheckquantity: async (qtyData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Registeredsales/checkquantity`,
        qtyData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Quantity not available`);
      }
    }
  },
  GetDistributor: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/VibeAdditionalCustomer`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Distributor: ${error.message}`);
    }
  },
  GetFGM: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/FG`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting FGM: ${error.message}`);
    }
  },
  GetCustomer: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Customer`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Customer: ${error.message}`);
    }
  },
  PostFGM: async (fgmData) => {
    try {
      const response = await apiReference.post(`${BASE_URL}/FG/AddFG`, fgmData);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  GetFGMById: async (id) => {
    try {
      const response = await apiReference.get(`/FG/User/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Stouckout Details: ${error.message}`);
    }
  },
  PostCustomer: async (customerData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Customer/AddCustomer`,
        customerData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  GetFGMcode: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Customer/FG`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting FGM: ${error.message}`);
    }
  },
  GetCustomercode: async () => {
    try {
      const response = await apiReference.get(
        `${BASE_URL}/Customer/CustomerFG`,
        {}
      );
      return response.data;
    } catch (error) {
      throw new Error(`Error getting FGM: ${error.message}`);
    }
  },
  PostCustomerFG: async (fgcustomerData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/CustomerFG/AddCustomerFG`,
        fgcustomerData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  GetFGMByName: async (name) => {
    try {
      const response = await apiReference.get(`/Customer/${name}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Stouckout Details: ${error.message}`);
    }
  },
  GetCustomerFGMByName: async (id) => {
    try {
      const response = await apiReference.get(`/Customer/CustomerFG/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Stouckout Details: ${error.message}`);
    }
  },
  GetCustomerFGMEditByid: async (id) => {
    try {
      const response = await apiReference.get(`/Customer/CustomerFGEdit/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Stouckout Details: ${error.message}`);
    }
  },
  GetSelectedCustomerFGMByName: async (id) => {
    try {
      const response = await apiReference.get(`/Customer/CustomerFGSelection/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Stouckout Details: ${error.message}`);
    }
  },
  PostNewSales: async (invoiceData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/SalesBill/AddSales`,
        invoiceData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  PostEditFG: async (putData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/EditCustomerFGPut/EditCustomerFGPut`,
        putData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  GetExpenseslist: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Expense`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting FGM: ${error.message}`);
    }
  },
  GetStockItemlist: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/StockItem`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting StockItem: ${error.message}`);
    }
  },
  GetLedgerlist: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/Ledger`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting FGM: ${error.message}`);
    }
  },
  GetSalesFGMcode: async () => {
    try {
      const response = await apiReference.get(
        `${BASE_URL}/SalesBill/SalesFG`,
        {}
      );
      return response.data;
    } catch (error) {
      throw new Error(`Error getting FGM: ${error.message}`);
    }
  },
  GetSalesFGMById: async (Salesno) => {
    try {
      const response = await apiReference.get(`/SalesBill/${Salesno}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Stouckout Details: ${error.message}`);
    }
  },
  PostDelivery: async (deliveryData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/Delivery/MultipleDelivery`,
        deliveryData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  FGMcode: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/FG/FG`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting FGM: ${error.message}`);
    }
  },
  GetFGMRWById: async (code) => {
    try {
      const response = await apiReference.get(`/FG/RWM/${code}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting Stouckout Details: ${error.message}`);
    }
  },
  PostCancelOrder: async (cancelData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/GroupMaster/MultipleGroupMaster`,
        cancelData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
  // Define other API functions here
};

export default api;
