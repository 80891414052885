// // eslint-disable-next-line no-unused-vars
// import React from 'react';
// import PropTypes from 'prop-types'; // Import PropTypes
// import { Route, Redirect } from 'react-router-dom';

// const ProtectedRoute = ({ component: Component, token, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       token ? <Component {...props} /> : <Redirect to="/" />
//     }
//   />
// );

// // Define PropTypes for props
// ProtectedRoute.propTypes = {
//   component: PropTypes.elementType.isRequired, // PropTypes for the component to render
//   token: PropTypes.string, // PropTypes for the authentication token
// };

// export default ProtectedRoute;



import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import AuthenticatedLayout from "./../layout/AuthenticatedLayout";

const ProtectedRoute = ({ token, children }) => {
  return token ? (
    <AuthenticatedLayout>{children}</AuthenticatedLayout>
  ) : (
    <Navigate to="/" />
  );
};

ProtectedRoute.propTypes = {
  token: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;



// import React from "react";
// import PropTypes from "prop-types";
// import { Navigate, Outlet } from "react-router-dom";
// import AuthenticatedLayout from "./../layout/AuthenticatedLayout";

// const ProtectedRoute = ({ token }) => {
//   return token ? (
//     <AuthenticatedLayout>
//       <Outlet />
//     </AuthenticatedLayout>
//   ) : (
//     <Navigate to="/" />
//   );
// };

// ProtectedRoute.propTypes = {
//   token: PropTypes.string,
// };

// export default ProtectedRoute;
