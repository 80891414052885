import React from "react";
import { Card, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Purchases = () => {
  return (
    <Container className="mt-4">
      <header className="mb-4">
        <h1>Purchases</h1>
      </header>
      <Card>
        <Card.Body>
          {/* This is an empty card. You can add content here later. */}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Purchases;
