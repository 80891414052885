import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import api from '../Services/api';  // Adjust the import according to your project structure

function SalesBillCard() {
  const [salesBills, setSalesBills] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSalesBills = async () => {
      try {
        const data = await api.GetVibeSalesBill();
        setSalesBills(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchSalesBills();
  }, []);

  const handleNewSalesOrder = () => {
    navigate('/Salesbill');
  };

  

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between mb-3">
        <h3>Sales Order Details</h3>
        <button className="btn btn-primary" onClick={handleNewSalesOrder}>
          New Sales Order
        </button>
      </div>
      <div className="card">
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                {/* <th>ID</th> */}
                <th>SO</th>
                <th>Customer</th>
                <th>Company</th>
                <th>Date</th>
                <th>Grand Total</th>
              </tr>
            </thead>
            <tbody>
              {salesBills.length > 0 ? (
                salesBills.map((bill) => (
                  <tr key={bill.id}>
                    {/* <td>{bill.id}</td> */}
                    <td>{bill.so}</td>
                    <td>{bill.customer}</td>
                    <td>{bill.company}</td>
                    <td>{bill.date}</td>
                    <td>{bill.grandtotal.toFixed(2)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SalesBillCard;
