// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "./Services/api";
import { useNavigate } from "react-router-dom";
import bhive from './bhive.webp';

function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleLogin = async () => {
    if (!username || !password) {
      toast.error("Please fill in both username and password fields.");
      if (!username) document.getElementById("username").focus();
      else document.getElementById("password").focus();
      return;
    }

    if (isLoggingIn) return;
    setIsLoggingIn(true);

    try {
      const response = await api.login(username, password);
      localStorage.setItem("token", response.token);
      localStorage.setItem("userId", response.userId);
      localStorage.setItem("name", response.name);
      localStorage.setItem("email", response.email);
      localStorage.setItem("mobile", response.mobile);
      window.location.reload();
      navigate("/dashboard");
    } catch (error) {
      toast.error(`Error: Login Failed.  ${error.message}`);
    } finally {
      setIsLoggingIn(false);
    }
  };

  const pageStyle = {
    backgroundImage: `url(${bhive})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px", // Adds padding for spacing
  };

  const loginBoxStyle = {
    margin: "20px",
    width: "100%",
    maxWidth: "400px",
  };

  const cardStyle = {
    background: "rgba(255, 255, 255, 0.2)", // Transparent white
    border: "1px solid rgba(255, 255, 255, 0.3)",
    borderRadius: "10px",
    backdropFilter: "blur(10px)",
    width: "100%",
    minHeight: "400px",
  };

  const inputStyle = {
    background: "rgba(255, 255, 255, 0.1)", // Slightly transparent input
    border: "1px solid rgba(255, 255, 255, 0.3)",
  };

  const buttonStyle = {
    backgroundColor: "#007bff", // Bootstrap primary color
    border: "none",
  };

  const logoStyle = {
    color: "white",
  };

  return (
    <div style={pageStyle}>
      <div className="login-box" style={loginBoxStyle}>
        <div className="login-logo">
          <a href="/" style={logoStyle}>
            {/* <b>Vibe International</b> */}
          </a>
        </div>

        <div className="card" style={cardStyle}>
          <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to start your session</p>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Login ID"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={inputStyle}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={inputStyle}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <a href="/">I forgot my password</a>
              </div>

              <div className="col-4">
                <button
                  onClick={handleLogin}
                  className="btn btn-primary btn-block"
                  disabled={isLoggingIn}
                  style={buttonStyle}
                >
                  {isLoggingIn ? "Wait.." : "Sign In"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Responsive Styles */}
      <style>
        {`
          @media (max-width: 768px) {
            .login-box {
              margin: 10px;
            }
            .card {
              width: 90%;
            }
          }

          @media (max-width: 576px) {
            .card {
              width: 100%;
            }
            .input-group-text span {
              font-size: 12px;
            }
          }
        `}
      </style>
    </div>
  );
}

export default Login;
