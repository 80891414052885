import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = () => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  useEffect(() => {
    // Fetch user details from localStorage when component mounts
    const name = localStorage.getItem("name");
    const email = localStorage.getItem("email");
    const mobile = localStorage.getItem("mobile");

    setUserDetails({
      name: name || "John Doe", // Default if not found in localStorage
      email: email || "johndoe@example.com", // Default if not found in localStorage
      mobile: mobile || "123-456-7890", // Default if not found in localStorage
    });
  }, []); // Empty dependency array ensures this runs only once

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("mobile");
    window.location.reload();
    window.location.href = "/login";
  };

  return (
    <nav className="main-header navbar navbar-expand navbar-dark" style={{
      background: 'linear-gradient(45deg, rgba(0,0,255,0.8), rgba(0,0,0,0.8))', // Blue and black gradient background
      boxShadow: '0 0 20px rgba(0,0,255,0.6)', // Blue glow
    }}>
      <ul className="navbar-nav">
        <li>
          {/* <h5>&nbsp;&nbsp;Vibe International</h5> */}
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link to="/dashboard" className="btn">
            <i className="fas fa-regular fa-home"></i>
          </Link>
        </li>
        &nbsp;
        <li className="nav-item">
          <Dropdown>
            <Dropdown.Toggle variant="info" id="dropdown-basic">
              <FaUserCircle size={16} />
            </Dropdown.Toggle>

            <Dropdown.Menu align="right">
              <Dropdown.ItemText>
                <strong>Name:</strong> {userDetails.name}
              </Dropdown.ItemText>
              <Dropdown.ItemText>
                <strong>Email:</strong> {userDetails.email}
              </Dropdown.ItemText>
              <Dropdown.ItemText>
                <strong>Contact:</strong> {userDetails.mobile}
              </Dropdown.ItemText>
            </Dropdown.Menu>
          </Dropdown>
        </li>
        &nbsp;
        <li align="right">
          <button onClick={handleLogout} className="btn">
            <i className="fas fa-sign-out-alt"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
