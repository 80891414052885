import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useNavigate } from "react-router-dom";
import api from "../Services/api";

function Deliverynote() {
  const [soDate, setSoDate] = useState("");
  const [customer, setCustomer] = useState("");
  const [soNumber, setSoNumber] = useState(""); // SO Number state
  const [soOptions, setSoOptions] = useState([]); // State for SO Number options
  const [inventory, setInventory] = useState([]);
  const [company, setCompany] = useState("");
  const [factory, setFactory] = useState("");
  const [factories, setFactories] = useState("");

  const [deliveryNoteNo] = useState("Auto Generated"); // Auto-generated Delivery Note No.
  const [vehicleNo, setVehicleNo] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverMobileNo, setDriverMobileNo] = useState("");
  const [odoMeter, setOdoMeter] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);
  const [taxableAmount, setTaxableAmount] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [igst, setIgst] = useState(0);
  const [roundoff, setRoundoff] = useState(0);
  const [customers, setCustomers] = useState([]); // Fetch data later
  const [selectedItemDetails, setSelectedItemDetails] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companyData = await api.GetVibeCompany();
        setCompanies(companyData);
      } catch (error) {
        console.error("Failed to fetch companies", error);
      }
    };

    const fetchCustomers = async () => {
      try {
        const customerData = await api.GetVibeCustomer(); // Assuming you have a function to fetch customers
        setCustomers(customerData);
      } catch (error) {
        console.error("Failed to fetch customers", error);
      }
    };

    const fetchItems = async () => {
      try {
        const itemData = await api.GetVibeGodown(); // Assuming you have a function to fetch item details
        setFactories(itemData);
      } catch (error) {
        console.error("Failed to fetch item details", error);
      }
    };

    fetchCompanies();
    fetchCustomers();
    fetchItems();
  }, []);

  // Fetch SO Numbers when a customer is selected
  const handleCustomerChange = async (selected) => {
    const customerId = selected[0]?.id || "";
    setCustomer(customerId);

    if (customerId) {
      try {
        const soData = await api.GetVibeSalesBillDropdown(customerId);
        setSoOptions(soData); // Assuming soData is an array of { id, so }
      } catch (error) {
        console.error("Failed to fetch SO numbers", error);
      }
    } else {
      setSoOptions([]);
    }
  };

  const handleSoNumberChange = async (selected) => {
    const soId = selected[0]?.id || "";
    setSoNumber(soId);

    if (soId) {
      try {
        const salesOrderDetails = await api.GetVibeSalesBillItem(soId);
        setInventory(salesOrderDetails); // Assuming the API returns an array of items
        updateGrandTotal(salesOrderDetails);
      } catch (error) {
        console.error("Failed to fetch sales order details", error);
      }
    } else {
      setInventory([]);
    }
  };

  const handleSave = async () => {
    const payload = {
      sodate: soDate,
      customer,
      sonumber: soNumber, // Include SO Number in the payload
      grandtotal: grandTotal,
      taxableAmount,
      cgst,
      sgst,
      igst,
      roundoff,
      inventory,
      company,
      factory,
      vehicleNo,
      driverName,
      driverMobileNo,
      odoMeter,
    };

    try {
      const response =  await api.PostVibeDelivery(payload);
      console.log("Payload saved successfully:", payload);
      navigate("/Deliverlist");
    } catch (error) {
      console.error("Failed to save delivery note:", error);
    }
  };

  const handleInventoryChange = (index, key, value) => {
    const updatedInventory = [...inventory];
    updatedInventory[index][key] = value;
    if (key === "qty" || key === "price") {
      updatedInventory[index].total = (
        (Number(updatedInventory[index].qty) || 0) *
        (Number(updatedInventory[index].price) || 0)
      ).toFixed(2);
    }
    setInventory(updatedInventory);
    updateGrandTotal(updatedInventory);
  };

  const updateGrandTotal = (updatedInventory) => {
    // Calculate the taxable amount based on the sum of all item totals
    const newTaxableAmount = updatedInventory
      .reduce((total, item) => total + (Number(item.total) || 0), 0)
      .toFixed(2);
  
    // Set the taxable amount
    setTaxableAmount(newTaxableAmount);
  
    // Example CGST, SGST, and IGST calculation based on the taxable amount
    const calculatedCgst = (newTaxableAmount * 0.09).toFixed(2); // 9% CGST
    const calculatedSgst = (newTaxableAmount * 0.09).toFixed(2); // 9% SGST
    const calculatedIgst = (newTaxableAmount * 0.18).toFixed(2); // 18% IGST
  
    // Set CGST, SGST, or IGST based on conditions
    setCgst(calculatedCgst);
    setSgst(calculatedSgst);
    setIgst(calculatedIgst);
  
    // Calculate the total tax based on applicable taxes
    const totalTax = Number(calculatedCgst) + Number(calculatedSgst) + Number(calculatedIgst);
  
    // Calculate the grand total as the sum of taxable amount and total tax
    let calculatedGrandTotal = (Number(newTaxableAmount) + totalTax).toFixed(2);
  
    // Calculate the round-off value
    const roundOffValue = (Math.round(calculatedGrandTotal) - calculatedGrandTotal).toFixed(2);
    setRoundoff(roundOffValue);
  
    // Update the grand total with round-off adjustment
    calculatedGrandTotal = (Number(calculatedGrandTotal) + Number(roundOffValue)).toFixed(2);
  
    // Set the grand total
    setGrandTotal(calculatedGrandTotal);
  };

 

  return (
    <div className="container mt-4">
      <h2>Delivery Note Voucher</h2>
      <div className="card shadow mt-3">
        <div className="card-body">
          <div className="row mb-3">
            <label htmlFor="soDate" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Delivery Date
            </label>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                id="deliveryDate"
                value={soDate}
                onChange={(e) => setSoDate(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="company" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Company
            </label>
            <div className="col-md-3">
              <Typeahead
                id="company"
                onChange={(selected) => {
                  setCompany(selected[0]?.id || "");
                }}
                options={companies} // Use fetched data
                labelKey="company" // Ensure this matches the actual data structure
                placeholder="Select Company"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="deliveryNoteNo" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Delivery Note No.
            </label>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                id="deliveryNoteNo"
                value={deliveryNoteNo}
                readOnly
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="vehicleNo" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Vehicle No.
            </label>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                id="vehicleNo"
                value={vehicleNo}
                onChange={(e) => setVehicleNo(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="driverName" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Driver Name
            </label>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                id="driverName"
                value={driverName}
                onChange={(e) => setDriverName(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="driverMobileNo" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Driver Mobile No.
            </label>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                id="driverMobileNo"
                value={driverMobileNo}
                onChange={(e) => setDriverMobileNo(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="customer" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Customer
            </label>
            <div className="col-md-3">
              <Typeahead
                id="customer"
                onChange={handleCustomerChange}
                options={customers} // Use fetched data
                labelKey="name" // Ensure this matches the actual data structure
                placeholder="Select Customer"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="soNumber" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Sales Order No.
            </label>
            <div className="col-md-3">
              <Typeahead
                id="soNumber"
                onChange={handleSoNumberChange}
                options={soOptions} // Use fetched SO number data
                labelKey="so" // Ensure this matches the actual data structure
                placeholder="Select SO Number"
              />
            </div>
          </div>
          <div className="row mb-3">
           <label htmlFor="factory" className="col-md-2 col-form-label" style={{ fontSize: '12px' }}>
              Godown
            </label>
            <div className="col-md-3">
              <Typeahead
                id="factory"
                onChange={(selected) => {
                  setFactory(selected[0]?.id || "");
                }}
                options={factories}
                labelKey="godown"
                placeholder="Select Godown"
              />
            </div>
            </div>

          {/* Inventory Table */}
          <div className="table-responsive">
            <table className="table table-bordered table-sm">
              <thead className="table-secondary">
                <tr style={{ fontSize: "12px" }}>
                  <th scope="col">S. No.</th>
                  {/* <th scope="col">Item Code</th> */}
                  <th scope="col">Item Name</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {inventory.map((item, index) => (
                  <tr key={index} style={{ fontSize: "12px" }}>
                    <th scope="row">{index + 1}</th>
                    {/* <td>{item.itemCode}</td> */}
                    <td>{item.item}</td>
                    <td>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        value={item.qty}
                        onChange={(e) =>
                          handleInventoryChange(index, "qty", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        value={item.price}
                        onChange={(e) =>
                          handleInventoryChange(index, "price", e.target.value)
                        }
                      />
                    </td>
                    <td>{item.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Tax and Total Fields */}
          <div className="mt-4">
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label" style={{ fontSize: '12px' }}>
                  Taxable Amount:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block"
                  id="taxableAmount"
                  value={taxableAmount}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label" style={{ fontSize: '12px' }}>
                  CGST:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block"
                  id="cgst"
                  value={cgst}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label" style={{ fontSize: '12px' }}>
                  SGST:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block"
                  id="sgst"
                  value={sgst}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label" style={{ fontSize: '12px' }}>
                  IGST:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block"
                  id="igst"
                  value={igst}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label" style={{ fontSize: '12px' }}>
                  Round Off:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block"
                  id="roundoff"
                  value={roundoff}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 text-end">
                <label className="col-form-label fw-bold" style={{ fontSize: '14px' }}>
                  Grand Total:&nbsp;
                </label>
                <input
                  type="text"
                  className="form-control d-inline-block fw-bold"
                  id="grandTotal"
                  value={grandTotal}
                  readOnly
                  style={{ width: "150px", display: 'inline' }}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <button
              className="btn btn-primary me-2"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/Deliverylist")}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deliverynote;
